import { observer } from 'mobx-react-lite';
import { useTranslate } from 'shared/hooks';
import { convertLotusesToMoney } from 'shared/helpers';
import { Currency } from 'shared/models';
import { Link as LinkIcon } from 'shared/ui';
import { useStore } from 'shared/stores';
import styles from '../replenishment-balance.module.scss';

const CostInAnotherCurrency = observer((): JSX.Element | null => {
  const { t } = useTranslate();
  const { commonStore, replenishmentBalanceStore } = useStore();
  const rates = commonStore.getRates;

  return rates ? (
    <a
      className={styles.anotherValue}
      href={`https://converter.by/cbr?calc_rub=${convertLotusesToMoney(
        Number(replenishmentBalanceStore.amountLotuses),
        Currency.RUB,
        rates,
      )}`}
      target='_blank'
      referrerPolicy='no-referrer'
      rel='noreferrer'
    >
      <LinkIcon /> {t('find_price_other_currencies')}
    </a>
  ) : null;
});

export { CostInAnotherCurrency };

import { ReactNode } from 'react';
import { Spinner } from 'shared/ui';
import styles from './pending-container.module.scss';

interface PendingContainerProps {
  children: ReactNode;
  value: boolean;
  className?: string;
}

function PendingContainer({ children, value, className = '' }: PendingContainerProps): JSX.Element {
  return value ? (
    <div className={`${styles.container} ${className}`}>
      <Spinner />
    </div>
  ) : (
    <>{children}</>
  );
}

export { PendingContainer };

import { makeAutoObservable } from 'mobx';
import { CurrenciesAnnotation } from 'shared/models';

interface IStore {
  numberWallet: string;
  amountLotuses: number;
  activeCurrency: CurrenciesAnnotation;
  userAgreements: boolean;
  setNumberWallet: (numberWallet: string) => void;
  setAmountLotuses: (amount: number) => void;
  setActiveCurrency: (currency: CurrenciesAnnotation) => void;
  setUserAgreements: (userAgreements: boolean) => void;
}

class WithdrawalStore implements IStore {
  numberWallet: string = '';

  amountLotuses: number = 0;

  activeCurrency: CurrenciesAnnotation = 'Bitcoin';

  userAgreements = true;

  constructor() {
    makeAutoObservable(this);
  }

  setNumberWallet(numberWallet: string): void {
    this.numberWallet = numberWallet;
  }

  setAmountLotuses(amount: number): void {
    this.amountLotuses = amount;
  }

  setActiveCurrency(currency: CurrenciesAnnotation): void {
    this.activeCurrency = currency;
  }

  setUserAgreements(userAgreements: boolean): void {
    this.userAgreements = userAgreements;
  }
}

const withdrawalStore = new WithdrawalStore();

export { withdrawalStore };

import { useTranslate } from 'shared/hooks';
import styles from './share-popup.module.scss';

function SharePopup(): JSX.Element {
  const { t } = useTranslate();
  return (
    <div className={styles.content}>
      {t('share_lotuses')}
    </div>
  );
}

export { SharePopup };

import { observer } from 'mobx-react-lite';
import { TextField } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import { useWithdrawalStore } from '../hooks';

const EnterNumberWallet = observer((): JSX.Element => {
  const { t } = useTranslate();
  const withdrawalStore = useWithdrawalStore();

  const onChangeEWallet = (value: string): void => {
    withdrawalStore.setNumberWallet(value);
  };

  return (
    <TextField
      onChange={onChangeEWallet}
      value={withdrawalStore.numberWallet}
      id='number-cash'
      name='number-cash'
      label={t('e_wallet_number') as string}
    />
  );
});

export { EnterNumberWallet };

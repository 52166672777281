import { User } from '../models';
import { privateApi } from './api';
import { authUsersUri, usersMeUri } from '../constants';

const userUri = usersMeUri;

export async function fetchUser(): Promise<User> {
  const res = await privateApi.get<User>(`${userUri}`);
  return res.data;
}

export interface SetAvatarUserResponse {
  new_avatar: string;
}

export async function setAvatarUser(image: FormData): Promise<SetAvatarUserResponse> {
  const res = await privateApi.post<SetAvatarUserResponse>(`${userUri}set_avatar/`, image);
  return res.data;
}

export type UpdatedUser = Pick<User, 'username' | 'first_last_name' | 'user_status'>;

export async function updateUser(updatedUser: UpdatedUser): Promise<User> {
  const res = await privateApi.patch<User>(`${userUri}`, updatedUser);
  return res.data;
}

export interface UserSettingsParams {
  receive_push?: boolean;
  receive_push_likes?: boolean;
  receive_push_subscriptions?: boolean;
  receive_push_new_messages?: boolean;
  locale?: string;
  userId: number;
}

export async function setUserSettings(params: UserSettingsParams): Promise<any> {
  await privateApi.post(`${authUsersUri}${params.userId}/settings/set/`);
}

export async function sendFeedback(email: string, text: string): Promise<void> {
  await privateApi.post(`${authUsersUri}feedback/`, { email, text });
}

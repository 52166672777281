import { privateApi, publicApi } from './api';
import { commonUri, paymentUri } from '../constants';
import { BuyStatuses, CurrenciesAnnotation, Currency, Rates, WithdrawalStatuses } from '../models';

const billingUri = `${commonUri}${paymentUri}`;

export async function fetchRates(): Promise<Rates> {
  const res = await publicApi.get<Rates>(`${billingUri}get_rates/`);
  return res.data;
}

export interface WithdrawalParams {
  wallet_type: Currency;
  wallet_address: string;
  amount: number;
}
export async function withdrawal(params: WithdrawalParams): Promise<void> {
  await privateApi.post(`${billingUri}withdrawal/`, params);
}

export interface FetchWithdrawalList {
  user: number;
  wallet_type: CurrenciesAnnotation;
  wallet_address: string;
  amount: number;
  status: WithdrawalStatuses;
  created_at: string;
}

export async function fetchWithdrawalList(): Promise<FetchWithdrawalList[]> {
  const res = await privateApi.get(`${billingUri}withdrawal/list/`);
  return res.data;
}

export interface ResponseBuy {
  confirmation_url: string;
}

export async function buy(lotus_count: number): Promise<ResponseBuy> {
  const res = await privateApi.post<ResponseBuy>(`${billingUri}buy/`, { lotus_count });
  return res.data;
}

export interface ResponseFetchBuyStatus {
  date: string;
  user: number;
  lotus_count: number;
  status: BuyStatuses;
  payment_token: string;
}

export async function fetchBuyStatus(payment_token: string): Promise<ResponseFetchBuyStatus> {
  const res = await privateApi.post<ResponseFetchBuyStatus>(`${billingUri}buy/status/`, {
    payment_token,
  });
  return res.data;
}

function formatPhoneNumber(value: string): string {
  const phoneNumber = value.replace(/[^\d]/g, '');

  if (!phoneNumber) {
    return '';
  }

  return `+${phoneNumber}`;
}

export { formatPhoneNumber };

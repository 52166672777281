import { useMemo, useState } from 'react';
import styles from './tabs.module.scss';

export interface Tab {
  title: string;
  content: JSX.Element | null;
}

interface TabsProps {
  tabs: Tab[];
  defaultTab: Tab;
}

interface ReturnedUseTabs {
  renderTabs: JSX.Element;
  activeTab: Tab;
  isPopup: boolean;
}

function useTabs({ tabs, defaultTab }: TabsProps): ReturnedUseTabs {
  const [activeTab, setActiveTab] = useState<Tab>(defaultTab);
  const [isPopup, setIsPopup] = useState(false);

  const selectTab =
    (tab: Tab): (() => void) =>
    (): void => {
      if (tab.content !== null) {
        setActiveTab(tab);
        setIsPopup(false);
      } else {
        setIsPopup((prevState) => !prevState);
      }
    };

  const renderTabs = useMemo(
    () =>
      tabs.map((t: Tab) => (
        <div
          key={t.title}
          onClick={selectTab(t)}
          className={`${styles.tab} ${activeTab.title === t.title ? styles.tabActive : ''}`}
        >
          {t.title}
        </div>
      )),
    [activeTab, tabs],
  );

  return {
    renderTabs: <div className={styles.container}>{renderTabs}</div>,
    activeTab,
    isPopup,
  };
}

export { useTabs };

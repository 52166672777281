import { CurrenciesAnnotation, Currency } from 'shared/models';

function normalizeCurrency(activeCurrency: CurrenciesAnnotation): Currency {
  switch (activeCurrency) {
    case 'Bitcoin':
      return Currency.BTC;
    case 'Etherium':
      return Currency.ETH;
    case 'USDT':
      return Currency.USDT;
    default:
      return Currency.BTC;
  }
}

export { normalizeCurrency };

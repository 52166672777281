import { observer } from 'mobx-react-lite';
import { Coin } from 'shared/ui';
import { declension } from 'shared/helpers';
import { useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';
import styles from './user-balance.module.scss';

interface UserBalanceProps {
  className?: string;
}

const UserBalance = observer(({ className = '' }: UserBalanceProps): JSX.Element => {
  const { t, language } = useTranslate();
  const endings = language === 'ru' ? ['а', 'ов', ''] : ['es', 'es', ''];
  const { userStore } = useStore();
  const balance = userStore.user.lotus_count;

  return (
    <div className={`${styles.containerBalance} ${className}`}>
      <Coin className={styles.containerBalanceIcon} />
      <div className={styles.containerBalanceContent}>
        <div className={styles.containerBalanceCount}>
          {balance} {declension(t('lotus'), endings, balance)}
        </div>
        <span className={styles.containerBalanceSign}>{t('your_balance')}</span>
      </div>
    </div>
  );
});

export { UserBalance };

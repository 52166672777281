// eslint-disable-next-line filename-rules/match
import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import translationEnglish from 'shared/translations/english/translation.json';
import translationRussian from 'shared/translations/russia/translation.json';
import { appKey } from 'shared/constants';

const resources = {
  en: {
    translation: translationEnglish,
  },
  ru: {
    translation: translationRussian,
  },
};

i18next
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: [
        'localStorage',
        'querystring',
        'cookie',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      lookupLocalStorage: `${appKey}_lang`,
      caches: ['localStorage'],
    },
  });

export default i18next;

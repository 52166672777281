import { makeAutoObservable } from 'mobx';
import { User } from 'shared/models';

export type CopyPartUser = Pick<User, 'first_last_name' | 'username' | 'user_status'>;

interface IUserStore {
  setUser: (user: User) => void;
  user: User;
  isAuth: boolean;
  reset: () => void;
  isPending: boolean;
  setIsPending: (flag: boolean) => void;
  updateAvatar: (avatar: string) => void;
  updateUsername: (username: string) => void;
  updateFirstName: (firstname: string) => void;
  updateUserStatus: (userStatus: string) => void;
  getCopyPartUser: CopyPartUser;
}

class UserStore implements IUserStore {
  user: User = Object.create(null);

  isPending = false;

  isAuth = false;

  copyPartUser: CopyPartUser = Object.create(null);

  constructor() {
    makeAutoObservable(this);
  }

  get getCopyPartUser(): CopyPartUser {
    return this.copyPartUser;
  }

  setIsPending(flag: boolean): void {
    this.isPending = flag;
  }

  reset(): void {
    this.isAuth = false;
    this.user = Object.create(null);
    this.isPending = false;
  }

  setUser(user: User): void {
    this.user = user;
    this.isAuth = true;
    this.copyPartUser = {
      first_last_name: user.first_last_name,
      user_status: user.user_status,
      username: user.username,
    };
  }

  updateAvatar(avatar: string): void {
    this.user.avatar_url = avatar;
  }

  updateFirstName(firstname: string): void {
    this.copyPartUser.first_last_name = firstname;
  }

  updateUsername(username: string): void {
    this.copyPartUser.username = username;
  }

  updateUserStatus(userStatus: string): void {
    this.copyPartUser.user_status = userStatus;
  }
}

export type { IUserStore };

export { UserStore };

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';

interface PlusProps {
  onClick?: () => void;
  className?: string;
}

function Plus({ onClick, className = '' }: PlusProps): JSX.Element {
  return <PlusIcon onClick={onClick} className={className} />;
}

export { Plus };

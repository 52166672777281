import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, TextField } from 'shared/ui';
import { validateEmail } from 'shared/helpers';
import { useStore } from 'shared/stores';
import { useTranslate } from 'shared/hooks';
import { ErrorFormList } from 'shared/constants';
import styles from '../register.module.scss';

interface EnterEmailProps {
  onSubmit: (email: string) => void;
}

const EnterEmail = observer(({ onSubmit }: EnterEmailProps): JSX.Element => {
  const [email, setEmail] = useState('');
  const { errorsStore } = useStore();
  const { t } = useTranslate();

  const onChangeEmail = (value: string): void => {
    setEmail(value);
  };

  const submit = (): void => {
    if (!validateEmail(email)) {
      errorsStore.setError(ErrorFormList.EMAIL, t('wrong_email'));
      return;
    }

    errorsStore.removeError(ErrorFormList.EMAIL);
    onSubmit(email);
  };

  return (
    <>
      <TextField
        id='email'
        name='email'
        label={t('your_email') as string}
        placeholder={t('enter_your_email') as string}
        value={email}
        onChange={onChangeEmail}
        error={errorsStore.getError(ErrorFormList.EMAIL)}
      />
      <p className={styles.info}>{t('approve_email')}</p>
      <Button
        className={`${styles.formSubmit} ${styles.continueBtn}`}
        title={t('continue')}
        variant='default'
        onClick={submit}
      />
    </>
  );
});

export { EnterEmail };

import { CloseAlt } from '../icons';
import styles from './error-msg.module.scss';

interface ErrorMsgProps {
  error: string;
  onClose?: () => void;
  className?: string;
}

function ErrorMsg({ error, onClose, className = '' }: ErrorMsgProps): JSX.Element {
  return (
    <div className={`${styles.container} ${className}`}>
      {error} <CloseAlt className={styles.containerIcon} onClick={onClose} />
    </div>
  );
}

export { ErrorMsg };

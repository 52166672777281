import { ChangeEvent, forwardRef, Ref, KeyboardEvent } from 'react';
import PhoneInput from 'react-phone-input-2'
import styles from './text-field.module.scss';

interface TextFieldProps {
  placeholder?: string;
  inputClassName?: string;
  className?: string;
  labelClassName?: string;
  label?: string;
  id: string;
  name: string;
  type?: 'text' | 'password';
  disabled?: boolean;
  icon?: JSX.Element;
  error?: string;
  pattern?: string;
  onChange?: (value: string) => void;
  value?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  variant?: 'phone'
}

const TextField = forwardRef(
  (
    {
      placeholder,
      inputClassName = '',
      label,
      id,
      name,
      className = '',
      labelClassName = '',
      type = 'text',
      disabled = false,
      icon,
      error,
      pattern,
      onChange,
      value,
      variant,
      onKeyDown,
    }: TextFieldProps,
    ref: Ref<HTMLInputElement>,
  ): JSX.Element => {
    const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
      if (onChange) onChange(event.target.value);
    };

    const changePhoneInput = (v: string): void => {
      if (onChange) onChange(v);
    }

    if (variant === 'phone') {
      return (
        <>
          <PhoneInput
            inputClass={styles.phoneInput}
            country='ru'
            value={value}
            onChange={changePhoneInput}
          />
          {error && <span className={styles.error}>{error}</span>}
        </>
      )
    }

    return (
      <div className={`${styles.container} ${className}`}>
        <label className={`${styles.label} ${labelClassName}`} htmlFor={id}>
          {label}
        </label>
        <div className={styles.inputContainer}>
          <input
            ref={ref}
            id={id}
            className={`${styles.input} ${inputClassName} ${error ? styles.errorInput : ''}`}
            type={type}
            placeholder={placeholder}
            name={name}
            disabled={disabled}
            pattern={pattern}
            onChange={changeHandler}
            value={value ?? ''}
            onKeyDown={onKeyDown}
          />
          <i className={styles.iconRight}>{icon}</i>
          {error && <span className={styles.error}>{error}</span>}
        </div>
      </div>
    );
  },
);

export { TextField };

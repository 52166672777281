import { makeAutoObservable } from 'mobx';
import { formCategoriesIdsFromCategories } from 'entities/category';
import { Category, Id } from '../models';

interface ICategoriesStore {
  setCategories: (categories: Category[]) => void;
  getCategories: Category[];
  getSelectedCategories: Id[];
  setSelectedCategoriesIds: (id: Id) => void;
  clearSelectedCategories: () => void;
  updateCategories: (ids: Id[]) => void;
}

class CategoriesStore implements ICategoriesStore {
  private categories: Category[] = [];

  selectedCategoriesIds: Id[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setCategories(categories: Category[]): void {
    this.categories = categories;
    this.selectedCategoriesIds = formCategoriesIdsFromCategories(
      categories.filter((c: Category) => c.selected),
    );
  }

  setSelectedCategoriesIds(categoryId: Id): void {
    const categoryIdIndex = this.selectedCategoriesIds.findIndex((id: Id) => id === categoryId);

    if (categoryIdIndex === -1) {
      this.selectedCategoriesIds.push(categoryId);
    } else {
      this.selectedCategoriesIds.splice(categoryIdIndex, 1);
    }
  }

  clearSelectedCategories(): void {
    this.selectedCategoriesIds = formCategoriesIdsFromCategories(
      this.categories.filter((c: Category) => c.selected),
    );
  }

  get getCategories(): Category[] {
    return this.categories;
  }

  get getSelectedCategories(): Id[] {
    return this.selectedCategoriesIds;
  }

  updateCategories(ids: Id[]): void {
    this.categories = this.categories.map((c: Category) => {
      const foundCategoryId = ids.find((id: Id) => id === c.id);

      if (foundCategoryId) {
        return { ...c, selected: true };
      }

      return { ...c, selected: false };
    });
  }
}

export type { ICategoriesStore };
export { CategoriesStore };

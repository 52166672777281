import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ErrorMsg, Paper } from 'shared/ui';
import { ErrorFormList, ROUTES } from 'shared/constants';
import { useSteps, useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';
import {
  isServerError,
  resetPassword,
  resetPasswordConfirm,
  resetPasswordPreConfirm,
} from 'shared/api';
import { EnterCode, EnterPhone, EnterNewPassword } from './components';
import styles from './restore-password.module.scss';

const RestorePassword = observer((): JSX.Element => {
  const { renderStep, nextStep } = useSteps();
  const [phone, setPhone] = useState('');
  const [token, setToken] = useState('');
  const navigator = useNavigate();
  const { errorsStore, userStore } = useStore();
  const restoreErrorMsg = errorsStore.getError(ErrorFormList.RESTORE_PASSWORD);
  const { t } = useTranslate();

  const enterPhoneSubmit = async (userPhone: string): Promise<void> => {
    userStore.setIsPending(true);

    try {
      await resetPassword(userPhone);
      setPhone(userPhone);
      errorsStore.removeError(ErrorFormList.MOBILE_PHONE);
    } catch (e) {
      if (isServerError(e) && e?.response?.data?.mobile_phone instanceof Array) {
        errorsStore.setError(ErrorFormList.MOBILE_PHONE, e.response.data.mobile_phone[0]);
      }

      userStore.setIsPending(false);
      return;
    }

    userStore.setIsPending(false);
    nextStep();
  };

  const enterCodeSubmit = async (code: string): Promise<void> => {
    userStore.setIsPending(true);

    try {
      const res = await resetPasswordPreConfirm(phone, code);
      setToken(res.code);
      errorsStore.removeError(ErrorFormList.MOBILE_PHONE);
    } catch (e) {
      if (isServerError(e) && e?.response?.data?.mobile_phone instanceof Array) {
        errorsStore.setError(ErrorFormList.MOBILE_PHONE, e.response.data.mobile_phone[0]);
      }
      userStore.setIsPending(false);
      return;
    }

    userStore.setIsPending(false);
    nextStep();
  };

  const enterRestorePasswordSubmit = async (password: string): Promise<void> => {
    userStore.setIsPending(true);

    try {
      await resetPasswordConfirm(password, phone, token);
    } catch (e) {
      if (isServerError(e) && typeof e?.response?.data?.detail === 'string') {
        errorsStore.setError(ErrorFormList.RESTORE_PASSWORD, e.response.data.detail);
      }

      userStore.setIsPending(false);
      return;
    }

    userStore.setIsPending(false);
    errorsStore.reset();
    navigator(ROUTES.login.to);
  };

  const closeErrorMsg = (): void => {
    errorsStore.removeError(ErrorFormList.RESTORE_PASSWORD);
  };

  return (
    <Paper className={styles.container} closeTo={ROUTES.login.to}>
      <div className={styles.content}>
        <h3 className={styles.title}>{t('restore-password')}</h3>
        {restoreErrorMsg && (
          <ErrorMsg onClose={closeErrorMsg} className={styles.errorMsg} error={restoreErrorMsg} />
        )}
        <div className={styles.form}>
          {renderStep([
            {
              element: <EnterPhone onSubmit={enterPhoneSubmit} />,
            },
            { element: <EnterCode variant='restore' phone={phone} onSubmit={enterCodeSubmit} /> },
            {
              element: <EnterNewPassword onSubmit={enterRestorePasswordSubmit} />,
            },
          ])}
        </div>
      </div>
    </Paper>
  );
});

export { RestorePassword };

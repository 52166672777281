import { makeAutoObservable } from 'mobx';

interface IReplenishmentBalanceStore {
  setIsUserAgree: (flag: boolean) => void;
  setAmountLotuses: (value: string) => void;
  setSum: (value: string) => void;
}

class ReplenishmentBalanceStore implements IReplenishmentBalanceStore {
  isUserAgree = true;

  amountLotuses = '0';

  sum = '0';

  constructor() {
    makeAutoObservable(this);
  }

  setAmountLotuses(value: string): void {
    this.amountLotuses = value;
  }

  setIsUserAgree(flag: boolean): void {
    this.isUserAgree = flag;
  }

  setSum(value: string): void {
    this.sum = value;
  }
}

export type { IReplenishmentBalanceStore };
export { ReplenishmentBalanceStore };

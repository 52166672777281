import { Currency, Rates } from '../models';

function getCurrency(rates: Rates, currency: Currency): string {
  switch (currency) {
    case Currency.RUB:
      return rates.rub;
    default:
      return rates.rub;
  }
}

export { getCurrency };

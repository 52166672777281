import { observer } from 'mobx-react-lite';
import { Checkbox } from 'shared/ui';
import { useStore } from 'shared/stores';

const ToggleUserAgreements = observer((): JSX.Element => {
  const { replenishmentBalanceStore } = useStore();

  const onChange = (checked: boolean): void => {
    replenishmentBalanceStore.setIsUserAgree(checked);
  };

  return (
    <Checkbox value={replenishmentBalanceStore.isUserAgree} id='agreements' onChange={onChange} />
  );
});

export { ToggleUserAgreements };

import { publicApi } from './api';
import { authUri } from '../constants';

export interface ResponseResetPassword {
  mobile_phone: [string];
}

const restorePasswordUri = authUri;

export async function resetPassword(mobile_phone: string): Promise<void> {
  // если у нас уже есть в локал сторейдже такой телефон и счетчик кода не протух, скипаем запрос, чтобы не спамить
  if(mobile_phone?.includes(localStorage.getItem('ditl-lk-code-phone') ?? '') && Math.floor((Number(localStorage.getItem('ditl-lk-code-counter')) - new Date().getTime()) / 1000) > 0) {
    return;
  }

  await publicApi.post<ResponseResetPassword>(`${restorePasswordUri}reset_password/`, {
    mobile_phone,
  });
}

interface ResponsePasswordPreConfirm {
  code: string;
  mobile_phone: string;
}

export async function resetPasswordPreConfirm(
  mobile_phone: string,
  code: string,
): Promise<ResponsePasswordPreConfirm> {
  const res = await publicApi.post<ResponsePasswordPreConfirm>(
    `${restorePasswordUri}reset_password_pre_confirm/`,
    { mobile_phone, code },
  );
  return res.data;
}

export async function resetPasswordConfirm(
  new_password: string,
  mobile_phone: string,
  code: string,
): Promise<void> {
  await publicApi.post(`${restorePasswordUri}reset_password_confirm/`, {
    new_password,
    mobile_phone,
    code,
  });
}

import { ReactNode, useState } from 'react';
import { Minus, Plus } from 'shared/ui';
import styles from './accordion.module.scss';

interface AccordionProps {
  children: ReactNode;
  title: string;
}

function Accordion({ children, title }: AccordionProps): JSX.Element {
  const [isShow, setIsShow] = useState(false);

  const toggleAccordion = (): void => {
    setIsShow(!isShow);
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerTitle} onClick={toggleAccordion}>
        <span className={isShow ? styles.containerTitleBold : ''}>{title}</span>
        {isShow ? (
          <Minus className={styles.containerTitleBtn} onClick={toggleAccordion} />
        ) : (
          <Plus className={styles.containerTitleBtn} onClick={toggleAccordion} />
        )}
      </div>
      {isShow && <div className={styles.containerContent}>{children}</div>}
    </div>
  );
}

export { Accordion };

import { Header } from 'widgets';
import { RestorePassword } from 'features/restore-password';
import { Container } from 'shared/ui';

function ForgetPasswordPage(): JSX.Element {
  return (
    <>
      <Header />
      <Container>
        <RestorePassword />
      </Container>
    </>
  );
}

export { ForgetPasswordPage };

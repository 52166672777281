import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ErrorMsg, Paper, Textarea, TextField } from 'shared/ui';
import { ErrorFormList, ROUTES } from 'shared/constants';
import { validateEmail } from 'shared/helpers';
import { useStore } from 'shared/stores';
import { useTranslate } from 'shared/hooks';
import { isServerError, sendFeedback } from 'shared/api';
import styles from './send-feedback.module.scss';

const SendFeedback = observer((): JSX.Element => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isPending, setIsPending] = useState(false);
  const { errorsStore } = useStore();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const errorMsg = errorsStore.getError(ErrorFormList.FEEDBACK);

  const changeEmail = (value: string): void => {
    setEmail(value);
  };

  const changeMessage = (value: string): void => {
    setMessage(value);
  };

  const onSubmit = async (): Promise<void> => {
    setIsPending(true);

    if (!validateEmail(email)) {
      errorsStore.setError(ErrorFormList.EMAIL, t('wrong_email'));
      setIsPending(false);
      return;
    }

    errorsStore.removeError(ErrorFormList.EMAIL);

    if (message === '') {
      errorsStore.setError(ErrorFormList.MESSAGE, t('require-field'));
      setIsPending(false);
      return;
    }

    errorsStore.removeError(ErrorFormList.MESSAGE);

    try {
      await sendFeedback(email, message);
      setIsPending(false);
      errorsStore.reset();
      navigate(ROUTES.successFeedback.to);
    } catch (e) {
      if (isServerError(e) && typeof e?.response?.data?.detail === 'string') {
        errorsStore.setError(ErrorFormList.FEEDBACK, e.response.data.detail);
      }
    }

    setIsPending(false);
  };

  const onCloseErrorMsg = (): void => {
    errorsStore.removeError(ErrorFormList.FEEDBACK);
  };

  return (
    <div className={styles.container}>
      <Paper className={styles.paper} closeTo={ROUTES.lk.to}>
        <h3 className={styles.paperTitle}>{t('feedback')}</h3>
        {errorMsg && <ErrorMsg error={errorMsg} onClose={onCloseErrorMsg} />}
        <form className={styles.paperForm}>
          <TextField
            id='email'
            name='email'
            label={t('email_for_contact') as string}
            placeholder={t('enter_email') as string}
            value={email}
            onChange={changeEmail}
            error={errorsStore.getError(ErrorFormList.EMAIL)}
          />
          <Textarea
            id='msg'
            name='message'
            placeholder={t('enter_msg') as string}
            label={t('your_msg') as string}
            className={styles.paperFormTextarea}
            value={message}
            onChange={changeMessage}
            error={errorsStore.getError(ErrorFormList.MESSAGE)}
          />
          <Button
            type='button'
            onClick={onSubmit}
            className={styles.paperFormSubmit}
            title={t('send')}
            disabled={isPending}
          />
        </form>
      </Paper>
    </div>
  );
});

export { SendFeedback };

import { useMemo } from 'react';
import { Accordion, Paper } from 'shared/ui';
import { useTrans, useTranslate } from 'shared/hooks';
import Mobile from 'shared/assets/images/mobile_2.png';
import MobileEn from 'shared/assets/images/mobile_2_en.png';
import styles from './instructions.module.scss';

function Instructions(): JSX.Element {
  const { t, language } = useTranslate();
  const Trans = useTrans();

  const renderImg = useMemo((): JSX.Element => {
    switch (language) {
      case 'ru':
        return <img className={styles.containerMobile} src={Mobile} alt='mobile' />;
      case 'en':
        return <img className={styles.containerMobile} src={MobileEn} alt='mobile' />;
      default:
        return <img className={styles.containerMobile} src={Mobile} alt='mobile' />;
    }
  }, [language]);

  return (
    <div className={styles.container}>
      {renderImg}
      <Paper className={styles.containerPaper}>
        <Accordion title={t('how_to_reg')}>
          <Trans i18nKey='how_to_reg_desc'>
            Для регистрации скачай наше приложение Ditl и установи его на мобильное устройство или
            перейти на наш сайт
            <a
              target='_blank'
              referrerPolicy='no-referrer'
              rel='noreferrer'
              href={window.location.origin}
            >
              ditl.
            </a>
            Далее нажми на кнопку «Зарегистрироваться» и введи свой номер телефона. Теперь вводи
            код, который пришел на твой номер телефона и продолжи регистрацию. После того как будет
            придуман никнейм и выбраны интересы, регистрация будет закончена и можно пользоваться
            приложением.
          </Trans>
        </Accordion>
        <Accordion title={t('how-to-provide-win-mp')}>{t('how_to_provide_win_mp_desc')}</Accordion>
        <Accordion title={t('how-to-create-cw-mp')}>
          <Trans i18nKey='how_to_create_cw_mp_desc'>
            Создать свой крипто-кошелек можно прямо в интернете. Завести его можно, например, на
            <a
              href='https://www.blockchain.com/'
              target='_blank'
              referrerPolicy='no-referrer'
              rel='noreferrer'
            >
              Blockchain.coms
            </a>
            , весь процесс достаточно простой и интуитивно понятный. Еще горячий кошелек можно
            завести через программу Electrum. Чтобы сделать это, установи программу на подключенный
            к интернету компьютер и создай новый кошелек, в качестве безопасности выбрав
            двухфакторную аутентификацию (понадобится мобильное приложение Google Authenticator) или
            multi-signature. После этого скопируй публичный ключ и пополни кошелек.
          </Trans>
        </Accordion>
        <Accordion title={t('how_to_start_advert')}>{t('how_to_start_advert_desc')}</Accordion>
        <Accordion title={t('how_to_download_my_music')}>
          {t('how_to_download_my_music_desc')}
        </Accordion>
      </Paper>
    </div>
  );
}

export { Instructions };

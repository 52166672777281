import { Textarea } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';
import { observer } from 'mobx-react-lite';

const EditDescription = observer((): JSX.Element => {
  const { userStore } = useStore();
  const { t } = useTranslate();

  const onChangeDescription = (value: string): void => {
    if (value.length < 79) userStore.updateUserStatus(value);
  };

  return (
    <Textarea
      rows={2}
      id='description'
      name='description'
      label={t('short_desc') as string}
      value={userStore.copyPartUser.user_status}
      onChange={onChangeDescription}
    />
  );
});

export { EditDescription };

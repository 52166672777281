export const ROUTES = {
  main: { to: '/' },
  login: { to: '/login' },
  forgetPassword: { to: '/forget-password' },
  register: { to: '/register' },
  lk: { to: '/lk' },
  profile: { to: '/lk/profile' },
  requestWithdrawal: { to: '/success-request-withdrawal' },
  successReplenishment: { to: '/success-replenishment' },
  balance: { to: '/lk/balance' },
  feedback: { to: '/feedback' },
  successFeedback: { to: '/success-feedback' },
  downloadApp: { to: '/download-app' },
  termsOfUse: { to: '/terms-of-use' },
  privacyPolicy: { to: '/privacy-policy' },
};

export type Routes = keyof typeof ROUTES;

import { Link, useNavigate } from 'react-router-dom';
import { ViewUserProfile } from 'features/view-user-profile';
import { UserBalance } from 'entities/user';
import { Button, Coin, Directions, Gear, Paper, Plus } from 'shared/ui';
import { ROUTES } from 'shared/constants';
import { useTranslate } from 'shared/hooks';
import styles from './lk-info.module.scss';

function LkInfo(): JSX.Element {
  const navigator = useNavigate();
  const { t } = useTranslate();

  const redirectToAddBalancePage = (): void => {
    navigator(ROUTES.balance.to, { state: { tab: 0 } });
  };

  const redirectToWithdrawBalancePage = (): void => {
    navigator(ROUTES.balance.to, { state: { tab: 1 } });
  };

  return (
    <div className={styles.container}>
      <Link className={styles.containerGearIcon} to={ROUTES.profile.to}>
        <Gear />
      </Link>
      <div className={styles.containerBalance}>
        <UserBalance />
        <Paper className={styles.paper} onClick={redirectToAddBalancePage}>
          <div className={styles.paperContent}>
            <div className={styles.icons}>
              <Coin className={styles.iconsCoin} />
              <div className={styles.iconsDots}>
                <i className={styles.iconsDot} />
                <i className={styles.iconsDot} />
              </div>
              <div className={styles.iconsPlus}>
                <Plus />
              </div>
            </div>
            <p className={styles.title}>{t('balance_replenishment')}</p>
          </div>
          <Button
            onClick={redirectToAddBalancePage}
            className={styles.addMoney}
            title={t('replenishment')}
          />
        </Paper>
        <Paper className={styles.paper} onClick={redirectToWithdrawBalancePage}>
          <div className={styles.paperContent}>
            <div className={styles.icons}>
              <Coin className={styles.iconsCoin} />
              <div className={styles.iconsDots}>
                <i className={styles.iconsDot} />
                <i className={styles.iconsDot} />
              </div>
              <div className={styles.iconsDirections}>
                <Directions />
              </div>
            </div>
            <p className={styles.title}>{t('funds_transfer_withdrawal')}</p>
          </div>
          <Button
            onClick={redirectToWithdrawBalancePage}
            className={styles.withdraw}
            title={t('withdrawal')}
          />
        </Paper>
      </div>
      <ViewUserProfile />
    </div>
  );
}

export { LkInfo };

import { memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChangeLocale } from 'features/change-locale';
import { UserMenu } from 'entities/user';
import { Arrow, Button, Logo } from 'shared/ui';
import { getJwt } from 'shared/helpers';
import { ROUTES } from 'shared/constants';
import { useTranslate } from 'shared/hooks';
import styles from './header.module.scss';

const Header = memo((): JSX.Element => {
  const navigate = useNavigate();
  const jwt = getJwt();
  const { t } = useTranslate();

  const navigateHandler =
    (path: string): (() => void) =>
    (): void => {
      navigate(path, { replace: true });
    };

  return (
    <header className={styles.header}>
      <div className={styles.headerLogoContainer}>
        <Logo className={styles.headerLogo} />
        {jwt && <div className={styles.headerLogoTitle}>DitL</div>}
        <ChangeLocale />
      </div>
      {!jwt && (
        <div className={styles.headerActions}>
          <Button
            className={styles.headerActionsLogin}
            title={t('log-in')}
            variant='rounded'
            icon={<Arrow />}
            onClick={navigateHandler('/login')}
          />
          <Button
            className={styles.headerActionsRegister2}
            title={t('registration2')}
            variant='rounded'
            icon={<Arrow />}
            onClick={navigateHandler('/register')}
          />
          <Button
            className={styles.headerActionsRegister}
            title={t('registration')}
            variant='rounded'
            icon={<Arrow />}
            onClick={navigateHandler('/register')}
          />
        </div>
      )}
      {jwt && (
        <div className={styles.authMenu}>
          <Link className={styles.authMenuItem} to={ROUTES.balance.to}>
            {t('balance-header')}
          </Link>
          <Link className={styles.authMenuItem} to={ROUTES.profile.to}>
            {t('settings')}
          </Link>
          <UserMenu />
        </div>
      )}
    </header>
  );
});

export { Header };

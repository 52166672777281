import { createContext, useContext } from 'react';
import { UserStore } from './user-store';
import { ErrorsStore } from './errors-store';
import { CategoriesStore } from './categories-store';
import { CommonStore } from './common-store';
import { ReplenishmentBalanceStore } from './replenishment-balance-store';

const store = {
  userStore: new UserStore(),
  errorsStore: new ErrorsStore(),
  categoriesStore: new CategoriesStore(),
  commonStore: new CommonStore(),
  replenishmentBalanceStore: new ReplenishmentBalanceStore(),
};

export const StoreContext = createContext(store);

export const useStore = (): typeof store => useContext<typeof store>(StoreContext);

export default store;

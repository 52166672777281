import { Navigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { getJwt } from 'shared/helpers';

interface RequireAuthProps {
  element: JSX.Element;
}

function RequireAuth({ element }: RequireAuthProps): JSX.Element {
  const jwt = getJwt();
  return jwt ? element : <Navigate to={ROUTES.login.to} />;
}

export { RequireAuth };

import { observer } from 'mobx-react-lite';
import { Category, Id } from 'shared/models';
import { Chip } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import styles from './category-list.module.scss';

interface CategoryListProps {
  categories: Category[];
  disabled?: boolean;
  onSelect?: (id: Id) => void;
  selectedCategoriesIds?: Id[];
}

const CategoryList = observer(
  ({ categories, disabled, onSelect, selectedCategoriesIds }: CategoryListProps): JSX.Element => {
    const { t } = useTranslate();

    const selectCategoryById = (id: Id): void => {
      if (typeof id === 'number' && onSelect) onSelect(id);
    };

    const renderCategories = (): JSX.Element[] => {
      let foundCategory: boolean | undefined = false;
      return categories.map((c: Category) => {
        foundCategory = selectedCategoriesIds?.some((id: Id) => id === c.id);
        return (
          <Chip
            entity={{ id: c.id, name: c.name, image: c.image_base64 }}
            disabled={disabled}
            onClick={selectCategoryById}
            key={c.id}
            isActive={foundCategory}
          />
        );
      });
    };

    return (
      <div className={styles.interests}>
        <p className={styles.interestsTitle}>{t('interests')}</p>
        <div className={styles.interestsContent}>{renderCategories()}</div>
      </div>
    );
  },
);

export { CategoryList };

import { ReactComponent as LinkIcon } from '../../../assets/icons/link.svg';

interface LinkProps {
  className?: string;
}

function Link({ className = '' }: LinkProps): JSX.Element {
  return <LinkIcon className={className} />;
}

export { Link };

import { ReactComponent as DotsIcon } from '../../../assets/icons/dots.svg';

interface DotsProps {
  className?: string;
}

function Dots({ className = '' }: DotsProps): JSX.Element {
  return <DotsIcon className={className} />;
}

export { Dots };

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { ErrorMsg, Paper } from 'shared/ui';
import { appKey, ErrorFormList, ROUTES } from 'shared/constants';
import { useSteps, useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';
import {
  auth,
  confirmValidationCode,
  fetchUser,
  fetchUserCategories,
  isServerError,
  registerUser,
  sendValidationCode,
  setUserSettings,
} from 'shared/api';
import { removeNonPlusChars } from 'shared/helpers';
import { EnterPhone, EnterCode, EnterEmail, EnterAboutSelf, AboutSelf } from './components';
import styles from './register.module.scss';

const Register = observer((): JSX.Element => {
  const { errorsStore, userStore, categoriesStore } = useStore();
  const navigator = useNavigate();
  const { renderStep, nextStep } = useSteps();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const registerErrorMsg = errorsStore.getError(ErrorFormList.REGISTER);
  const { t, language } = useTranslate();

  useEffect(
    () => () => {
      errorsStore.reset();
    },
    [errorsStore],
  );

  const enterPhoneSubmit = async (userPhone: string): Promise<void> => {
    userStore.setIsPending(true);

    try {
      await sendValidationCode(removeNonPlusChars(userPhone));
      errorsStore.removeError(ErrorFormList.MOBILE_PHONE);
    } catch (e) {
      if (isServerError(e) && typeof e?.response?.data?.mobile_phone === 'string') {
        errorsStore.setError(ErrorFormList.MOBILE_PHONE, e.response.data.mobile_phone);
      }

      userStore.setIsPending(false);
      return;
    }

    setPhone(userPhone);
    userStore.setIsPending(false);
    nextStep();
  };

  const enterCodeSubmit = async (code: string): Promise<void> => {
    userStore.setIsPending(true);

    try {
      const res = await confirmValidationCode(code, removeNonPlusChars(phone));
      setToken(res.token);
    } catch (e) {
      if (isServerError(e) && e?.response?.data?.code instanceof Array) {
        errorsStore.setError(ErrorFormList.CODE, e.response.data.code[0]);
      }

      userStore.setIsPending(false);
      return;
    }

    errorsStore.removeError(ErrorFormList.CODE);
    userStore.setIsPending(false);
    nextStep();
  };

  const enterEmailSubmit = (userEmail: string): void => {
    setEmail(userEmail);
    nextStep();
  };

  const enterAboutSelf = async ({
    password,
    nickName,
    name,
    description,
  }: AboutSelf): Promise<void> => {
    userStore.setIsPending(true);

    const newUser = {
      mobile_phone: removeNonPlusChars(phone),
      email,
      token,
      password,
      first_last_name: name,
      username: nickName,
      user_status: description,
      categories: categoriesStore.getSelectedCategories,
    };

    try {
      await registerUser(newUser);
    } catch (e) {
      if (isServerError(e) && typeof e?.response?.data?.detail === 'string') {
        errorsStore.setError(ErrorFormList.REGISTER, e.response.data.detail);
      }

      userStore.setIsPending(false);
      return;
    }

    errorsStore.reset();

    try {
      const authRes = await auth(removeNonPlusChars(phone), password);
      localStorage.setItem(`${appKey}_jwt`, authRes.access);
      localStorage.setItem(`${appKey}_jwtR`, authRes.refresh);
    } catch (e) {
      throw new Error('login is failed');
    }

    const resUser = await fetchUser();
    userStore.setUser(resUser);

    await setUserSettings({
      receive_push_new_messages: true,
      receive_push_likes: true,
      receive_push_subscriptions: true,
      receive_push: true,
      locale: language,
      userId: resUser.id,
    });

    const userCategoriesRes = await fetchUserCategories();

    categoriesStore.setCategories(userCategoriesRes);

    userStore.setIsPending(false);
    navigator(ROUTES.downloadApp.to);
  };

  const closeErrorMsg = (): void => {
    errorsStore.removeError(ErrorFormList.REGISTER);
  };

  return (
    <Paper className={styles.container} closeTo={ROUTES.main.to}>
      <div className={styles.content}>
        <h3 className={styles.title}>{t('registration2')}</h3>
        {registerErrorMsg && (
          <ErrorMsg onClose={closeErrorMsg} className={styles.errorMsg} error={registerErrorMsg} />
        )}
        <div className={styles.form}>
          {renderStep([
            {
              element: <EnterPhone onSubmit={enterPhoneSubmit} />,
            },
            {
              element: <EnterCode phone={phone} onSubmit={enterCodeSubmit} />,
            },
            {
              element: <EnterEmail onSubmit={enterEmailSubmit} />,
            },
            {
              element: <EnterAboutSelf onSubmit={enterAboutSelf} />,
            },
          ])}
        </div>
      </div>
    </Paper>
  );
});

export { Register };

import { Header, DownloadAppLinks } from 'widgets';
import { Container, Paper } from 'shared/ui';
import { ROUTES } from 'shared/constants';
import DownloadAppImg from 'shared/assets/images/downdload-app.png';
import styles from './download-app-page.module.scss';

function DownloadAppPage(): JSX.Element {
  return (
    <>
      <Header />
      <Container>
        <Paper className={styles.container} closeTo={ROUTES.lk.to}>
          <div className={styles.containerImgContainer}>
            <DownloadAppLinks className={styles.containerImgContainerLinks} />
            <img src={DownloadAppImg} alt='download application' />
          </div>
          <h4>Скачайте мобильное приложение DitL</h4>
          <p>Отсканируйте QR-код, чтобы скачать</p>
        </Paper>
      </Container>
    </>
  );
}

export { DownloadAppPage };

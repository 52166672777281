import { Id } from '../../models';
import styles from './chip.module.scss';

export interface Entity {
  name: string;
  image?: string;
  id: Id;
}

interface ChipProps {
  onClick: (id: Id) => void;
  disabled?: boolean;
  entity: Entity;
  isActive?: boolean;
}

function Chip({ entity, onClick, disabled = false, isActive = false, }: ChipProps): JSX.Element {
  const selectHandler = (): void => {
    if (!disabled) {
      onClick(entity.id);
    }
  };

  return (
    <div
      onClick={selectHandler}
      className={`${styles.chip} ${isActive ? styles.chipSelected : ''} ${
        disabled ? styles.chipDisabled : ''
      }`}
    >
      {entity.image && <img src={`data:image/png;base64,${entity.image}`} alt={entity.name} />}
      <span>{entity.name}</span>
    </div>
  );
}

export { Chip };

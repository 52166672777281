import { WithdrawalStatuses } from 'shared/models';
import { useTranslate } from 'shared/hooks';
import styles from './request-status.module.scss';

interface RequestStatusProps {
  status: WithdrawalStatuses;
}

function RequestStatus({ status }: RequestStatusProps): JSX.Element {
  const { t } = useTranslate();

  const renderStatus = (): JSX.Element => {
    switch (status) {
      case 'pending':
        return <div className={`${styles.status} ${styles.statusPending}`}>{t(status)}</div>;
      case 'completed':
        return <div className={`${styles.status} ${styles.statusCompleted}`}>{t(status)}</div>;
      case 'cancelled':
        return <div className={`${styles.status} ${styles.statusCancelled}`}>{t(status)}</div>;
      default:
        // eslint-disable-next-line no-case-declarations
        const smt: never = status;
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`${smt} is not request status`);
    }
  };

  return renderStatus();
}

export { RequestStatus };

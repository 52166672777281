import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button } from 'shared/ui';
import { useStore } from 'shared/stores';
import { isServerError, withdrawal } from 'shared/api';
import { ErrorFormList, ROUTES } from 'shared/constants';
import { useTranslate } from 'shared/hooks';
import { normalizeCurrency } from '../helpers';
import { useWithdrawalStore } from '../hooks';
import styles from '../withdrawal-balance.module.scss';

interface ReplenishmentSubmitProps {
  setIsPending: (flag: boolean) => void;
}

const WithdrawalSubmit = observer(({ setIsPending }: ReplenishmentSubmitProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { errorsStore } = useStore();
  const withdrawalStore = useWithdrawalStore();

  const onSubmit = async (): Promise<void> => {
    if (withdrawalStore.amountLotuses && withdrawalStore.numberWallet) {
      setIsPending(true);

      try {
        await withdrawal({
          wallet_type: normalizeCurrency(withdrawalStore.activeCurrency),
          wallet_address: withdrawalStore.numberWallet,
          amount: withdrawalStore.amountLotuses,
        });

        setIsPending(false);
        navigate(ROUTES.requestWithdrawal.to);

        errorsStore.removeError(ErrorFormList.WITHDRAWAL);
      } catch (e) {
        if (isServerError(e) && e?.response?.data?.amount instanceof Array) {
          errorsStore.setError(ErrorFormList.WITHDRAWAL, e.response.data.amount[0]);
        }

        setIsPending(false);
      }
    } else {
      errorsStore.setError(ErrorFormList.WITHDRAWAL, t('withdrawal_error_requires'));
    }
  };

  return (
    <Button
      disabled={
        !withdrawalStore.userAgreements ||
        withdrawalStore.numberWallet === '' ||
        withdrawalStore.amountLotuses === 0
      }
      onClick={onSubmit}
      className={styles.replenishment}
      title={t('withdrawal')}
    />
  );
});

export { WithdrawalSubmit };

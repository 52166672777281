import { observer } from 'mobx-react-lite';
import { UserAvatar } from 'entities/user';
import { CategoryList } from 'entities/category';
import { TextField } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';
import { Category } from 'shared/models';
import styles from './view-user-profile.module.scss';

const ViewUserProfile = observer((): JSX.Element => {
  const {
    userStore: { user },
  } = useStore();
  const { categoriesStore } = useStore();
  const userCategories = categoriesStore.getCategories.filter((c: Category) => c.selected);
  const { t } = useTranslate();

  return (
    <div className={styles.user}>
      <UserAvatar disabled />
      <div className={styles.form}>
        <TextField
          value={user.username}
          id='nickname'
          name='nickname'
          label={t('link') as string}
          disabled
        />
        <TextField
          id='name'
          name='name'
          label={t('name_surname') as string}
          disabled
          value={user.first_last_name}
        />
        <TextField
          id='phone'
          name='phone'
          label={t('phone') as string}
          disabled
          value={user.mobile_phone}
        />
        <TextField
          id='description'
          name='description'
          label={t('short_desc') as string}
          disabled
          value={user.user_status}
        />
        <CategoryList disabled categories={userCategories} />
      </div>
    </div>
  );
});

export { ViewUserProfile };

import { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import { ReactComponent as CheckboxIcon } from '../../assets/icons/checkbox.svg';
import styles from './checkbox.module.scss';

interface CheckboxProps {
  label?: string;
  id: string;
  onChange?: (flag: boolean) => void;
  value: boolean;
}

const Checkbox = forwardRef(
  (
    { label, id, onChange, value }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>): void => {
      if (onChange) onChange(e.target.checked);
    };

    return (
      <div className={styles.checkbox}>
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
        {value && <CheckboxIcon className={styles.checkboxIcon} />}
        <input
          ref={ref}
          checked={value}
          className={styles.input}
          id={id}
          onChange={onChangeCheckbox}
          type='checkbox'
        />
      </div>
    );
  },
);

export { Checkbox };

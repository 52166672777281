import { useCallback, useState } from 'react';
import useResizeObserver, { UseResizeObserverCallback } from '@react-hook/resize-observer';
import { Document, Page, pdfjs } from 'react-pdf';
import styles from './styles.module.scss';

interface PdfContainerProps {
  file: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const maxWidth = 1024;

export function PdfContainer({ file }: PdfContainerProps): JSX.Element {
  const [pages, setPages] = useState<number>();
  const [containerWidth, setContainerWidth] = useState<number>();
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setPages(numPages);
  };

  const onResize = useCallback<UseResizeObserverCallback>((entry) => {
    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, onResize);

  return (
    <div ref={setContainerRef} className={styles.container}>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(pages), (_el, index) => (
          <Page
            renderTextLayer={false}
            renderAnnotationLayer={false}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
          />
        ))}
      </Document>
    </div>
  );
}

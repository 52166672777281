import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CategoryList } from 'entities/category';
import { Button, ErrorMsg } from 'shared/ui';
import { useStore } from 'shared/stores';
import { fetchUserCategories, saveCategories, updateUser as updateUserApi } from 'shared/api';
import { ErrorFormList, ROUTES } from 'shared/constants';
import { Id } from 'shared/models';
import { useTranslate } from 'shared/hooks';
import {
  EditDescription,
  EditNickname,
  EditPhone,
  EditUserAvatar,
  EditUsername,
} from './components';
import styles from './edit-user-profile.module.scss';

const EditUserProfile = observer((): JSX.Element => {
  const { userStore, errorsStore, categoriesStore } = useStore();
  const avatarErrorMsg = errorsStore.getError(ErrorFormList.AVATAR);
  const navigator = useNavigate();
  const { t } = useTranslate();

  const closeErrorMsg = (): void => {
    errorsStore.reset();
  };

  const onSelectCategory = (id: Id): void => {
    categoriesStore.setSelectedCategoriesIds(id);
  };

  const cancelEditProfile = (): void => {
    categoriesStore.clearSelectedCategories();
    userStore.setUser(userStore.user);
    navigator(ROUTES.lk.to);
  };

  const updateUser = async (): Promise<void> => {
    userStore.setIsPending(true);

    userStore.setUser({ ...userStore.user, ...userStore.getCopyPartUser });

    const selectedCategoriesIds = [...categoriesStore.selectedCategoriesIds];

    const results = await Promise.allSettled([
      saveCategories(selectedCategoriesIds),
      fetchUserCategories(),
      updateUserApi({
        username: userStore.user.username,
        first_last_name: userStore.user.first_last_name,
        user_status: userStore.user.user_status,
      }),
    ]);

    const saveCategoriesRes = results[0];
    const userCategoriesRes = results[1];
    const updatedUser = results[2];

    if (saveCategoriesRes.status === 'fulfilled') {
      categoriesStore.updateCategories(selectedCategoriesIds);
    }

    if (updatedUser.status === 'fulfilled') {
      userStore.setUser(updatedUser.value);
      userStore.setIsPending(false);
      navigator(ROUTES.lk.to);
    }

    if (
      saveCategoriesRes.status === 'rejected' ||
      userCategoriesRes.status === 'rejected' ||
      updatedUser.status === 'rejected'
    ) {
      categoriesStore.clearSelectedCategories();
      userStore.setUser(userStore.user);
      userStore.setIsPending(false);
    }
  };

  return (
    <>
      <div className={styles.user}>
        <EditUserAvatar />
        {avatarErrorMsg && (
          <ErrorMsg className={styles.errorMsg} error={avatarErrorMsg} onClose={closeErrorMsg} />
        )}
        <div className={styles.form}>
          <EditNickname />
          <EditUsername />
          <EditPhone />
          <EditDescription />
          <CategoryList
            selectedCategoriesIds={categoriesStore.getSelectedCategories}
            onSelect={onSelectCategory}
            categories={categoriesStore.getCategories}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          disabled={userStore.isPending}
          onClick={updateUser}
          className={styles.saveBtn}
          title={t('save')}
        />
        <Button onClick={cancelEditProfile} className={styles.actionsCancel} title={t('cancel')} />
      </div>
    </>
  );
});

export { EditUserProfile };

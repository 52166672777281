import { useNavigate } from 'react-router-dom';
import { Header } from 'widgets';
import { Accept, Button, Container, Paper } from 'shared/ui';
import { ROUTES } from 'shared/constants';
import { useTranslate } from 'shared/hooks';
import styles from './success-request-withdrawal-funds-page.module.scss';

function SuccessRequestWithdrawalFundsPage(): JSX.Element {
  const navigator = useNavigate();
  const { t } = useTranslate();

  const finishHandler = (): void => {
    navigator(ROUTES.lk.to);
  };

  return (
    <>
      <Header />
      <Container className={styles.container}>
        <Paper className={styles.paper} closeTo={ROUTES.lk.to}>
          <i className={styles.acceptIcon}>
            <Accept />
          </i>
          <h3 className={styles.title}>{t('successfully_withdrawal')}</h3>
          <p className={styles.description}>{t('money_will_come')}</p>
          <Button onClick={finishHandler} className={styles.finish} title={t('finish')} />
        </Paper>
      </Container>
    </>
  );
}

export { SuccessRequestWithdrawalFundsPage };

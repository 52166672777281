import { getCurrentYear } from 'shared/helpers';
import { useTranslate } from 'shared/hooks';
import { Link } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import styles from './footer.module.scss';

function Footer(): JSX.Element {
  const { t } = useTranslate();
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <span className={styles.footerAddress}>{t('address')}</span>
        {/* <a className={`${styles.footerContact}`} href='tel:+80000000000'> */}
        {/*  +8 000 000 00 00 */}
        {/* </a> */}
        <a
          className={`${styles.footerContact} ${styles.footerEmail}`}
          href='mailto:diamondinthelotusditl@gmail.com'
        >
          diamondinthelotusditl@gmail.com
        </a>
        <Link
          className={`${styles.footerLink} ${styles.footerUserAgreement}`}
          to={ROUTES.privacyPolicy.to}
          rel='noreferrer'
          target='_blank'
          referrerPolicy='no-referrer'
        >
          {t('privacy-policy')}
        </Link>
        <Link
          className={styles.footerLink}
          to={ROUTES.termsOfUse.to}
          rel='noreferrer'
          target='_blank'
          referrerPolicy='no-referrer'
        >
          {t('user-agreement')}
        </Link>
        <div className={styles.footerCreator}>DitL (c) {getCurrentYear()}</div>
      </div>
    </footer>
  );
}

export { Footer };

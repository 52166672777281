import { Link } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { ReactComponent as LogoSvg } from '../../../assets/icons/logo.svg';

interface LogoProps {
  className?: string;
}

function Logo({ className = '' }: LogoProps): JSX.Element {
  return (
    <Link to={ROUTES.main.to}>
      <LogoSvg className={className} />
    </Link>
  );
}

export { Logo };

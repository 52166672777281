import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { MainPage } from 'pages/main-page/main-page';
import { LoginPage } from 'pages/login-page';
import { ForgetPasswordPage } from 'pages/forget-password-page';
import { RegisterPage } from 'pages/register-page';
import { SuccessRequestWithdrawalFundsPage } from 'pages/success-request-withdrawal-funds-page';
import { LkPage } from 'pages/lk-page';
import { ProfilePage } from 'pages/profile-page';
import { SuccessReplenishmentPage } from 'pages/success-replenishment-page';
import { BalancePage } from 'pages/balance-page';
import { FeedbackPage } from 'pages/feedback-page';
import { SuccessFeedback } from 'pages/success-feedback';
import { DownloadAppPage } from 'pages/download-app-page';
import { UserAgreementPage } from 'pages/user-agreement-page';
import { TermOfUsePage } from 'pages/term-of-use-page';
import { RequireAuth } from 'features/require-auth';
import { ROUTES } from 'shared/constants';

const router = createBrowserRouter([
  {
    path: ROUTES.main.to,
    element: <MainPage />,
  },
  {
    path: ROUTES.login.to,
    element: <LoginPage />,
  },
  {
    path: ROUTES.forgetPassword.to,
    element: <ForgetPasswordPage />,
  },
  {
    path: ROUTES.register.to,
    element: <RegisterPage />,
  },
  {
    path: ROUTES.lk.to,
    element: <RequireAuth element={<LkPage />} />,
  },
  {
    path: ROUTES.profile.to,
    element: <RequireAuth element={<ProfilePage />} />,
  },
  {
    path: ROUTES.requestWithdrawal.to,
    element: <SuccessRequestWithdrawalFundsPage />,
  },
  {
    path: ROUTES.successReplenishment.to,
    element: <SuccessReplenishmentPage />,
  },
  {
    path: ROUTES.balance.to,
    element: <RequireAuth element={<BalancePage />} />,
  },
  {
    path: ROUTES.feedback.to,
    element: <RequireAuth element={<FeedbackPage />} />,
  },
  {
    path: ROUTES.successFeedback.to,
    element: <RequireAuth element={<SuccessFeedback />} />,
  },
  {
    path: ROUTES.downloadApp.to,
    element: <DownloadAppPage />,
  },
  {
    path: ROUTES.privacyPolicy.to,
    element: <UserAgreementPage />,
  },
  {
    path: ROUTES.termsOfUse.to,
    element: <TermOfUsePage />,
  },
]);

function Routing(): JSX.Element {
  return <RouterProvider router={router} />;
}

export { Routing };

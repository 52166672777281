import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Arrow, DefaultAvatar } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import { ROUTES } from 'shared/constants';
import { removeJwt } from 'shared/helpers';
import { useStore } from 'shared/stores';
import styles from './user-menu.module.scss';

const UserMenu = observer((): JSX.Element | null => {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const navigator = useNavigate();
  const { userStore } = useStore();
  const localeMenuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslate();

  const handleClickOutside = (event: MouseEvent): void => {
    if (localeMenuRef.current && !localeMenuRef.current.contains(event.target as Node)) {
      setIsShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleMenu = (): void => {
    setIsShowMenu(!isShowMenu);
  };

  const logout = (): void => {
    removeJwt();
    navigator(ROUTES.main.to);
  };

  return userStore.user.first_last_name ? (
    <div className={styles.container} ref={localeMenuRef}>
      <div className={styles.menu} onClick={toggleMenu}>
        <div className={styles.imgContainer}>
          {userStore.user?.avatar_url ? (
            <img src={userStore.user.avatar_url} alt='avatar' />
          ) : (
            <DefaultAvatar />
          )}
        </div>
        <span className={styles.menuUserName}>{userStore.user.first_last_name}</span>
        <Arrow className={styles.menuIcon} />
      </div>
      {isShowMenu && (
        <div className={styles.list}>
          <Link to={ROUTES.lk.to}>{t('profile')}</Link>
          <Link to={ROUTES.profile.to}>{t('settings')}</Link>
          <Link to={ROUTES.feedback.to}>{t('feedback')}</Link>
          <span onClick={logout}>{t('exit')}</span>
        </div>
      )}
    </div>
  ) : null;
});

export { UserMenu };

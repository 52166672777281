import { useState } from 'react';
import { Step } from 'shared/ui';

interface ReturnedUseStep {
  renderStep: (steps: Step[]) => JSX.Element;
  nextStep: () => void;
}

function useSteps(): ReturnedUseStep {
  const [step, setStep] = useState(0);

  const nextStep = (): void => {
    setStep(step + 1);
  };

  const renderStep = (steps: Step[]): JSX.Element => steps[step].element;

  return {
    renderStep,
    nextStep,
  };
}

export { useSteps };

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CategoryList } from 'entities/category';
import { Button, Textarea, TextField } from 'shared/ui';
import { isPasswordValid, validatePasswords } from 'shared/helpers';
import { getCategories, isServerError, validatePassword, validateUserName } from 'shared/api';
import { useStore } from 'shared/stores';
import { ErrorFormList } from 'shared/constants';
import { Id } from 'shared/models';
import { useTranslate } from 'shared/hooks';
import styles from '../register.module.scss';

export interface AboutSelf {
  password: string;
  nickName: string;
  name: string;
  description: string;
}

interface EnterAboutSelfProps {
  onSubmit: (aboutSelf: AboutSelf) => void | Promise<void>;
}

const EnterAboutSelf = observer(({ onSubmit }: EnterAboutSelfProps): JSX.Element => {
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [nickName, setNickName] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { errorsStore, userStore, categoriesStore } = useStore();
  const { t } = useTranslate();

  useEffect(() => {
    getCategories().then((res) => {
      categoriesStore.setCategories(res.results);
    });
  }, [categoriesStore]);

  const onChangePassword = (value: string): void => {
    if (isPasswordValid(value)) setPassword(value);
  };

  const onChangeRepeatedPassword = (value: string): void => {
    setRepeatedPassword(value);
  };

  const onChangeNickname = (value: string): void => {
    setNickName(value);
  };

  const onChangeName = (value: string): void => {
    setName(value);
  };

  const onChangeDescription = (value: string): void => {
    if (value.length < 79) setDescription(value);
  };

  const submit = async (): Promise<void> => {
    userStore.setIsPending(true);

    try {
      await validateUserName(nickName);
      userStore.setIsPending(false);
      errorsStore.removeError(ErrorFormList.USERNAME);
    } catch (e) {
      if (isServerError(e) && e?.response?.data?.username instanceof Array) {
        errorsStore.setError(ErrorFormList.USERNAME, e.response.data.username[0]);
      }

      userStore.setIsPending(false);
      return;
    }

    try {
      await validatePassword(password);
      userStore.setIsPending(false);
      errorsStore.removeError(ErrorFormList.PASSWORD);
    } catch (e) {
      if (isServerError(e) && e?.response?.data?.password instanceof Array) {
        errorsStore.setError(ErrorFormList.PASSWORD, e.response.data.password[0]);
      }

      userStore.setIsPending(false);
      return;
    }

    if (!validatePasswords(password, repeatedPassword)) {
      errorsStore.removeError(ErrorFormList.REPEAT_PASSWORD);
      errorsStore.setError(ErrorFormList.REPEAT_PASSWORD, t('passwords_not_equals'));
      userStore.setIsPending(false);
      return;
    }

    userStore.setIsPending(false);
    errorsStore.reset();

    onSubmit({
      password,
      nickName,
      name,
      description,
    });
  };

  const onSelectCategory = (id: Id): void => {
    categoriesStore.setSelectedCategoriesIds(id);
  };

  return (
    <>
      <TextField
        id='password'
        name='password'
        label={t('password-placeholder') as string}
        placeholder={t('password-placeholder') as string}
        type='password'
        className={styles.textFieldCode}
        value={password}
        onChange={onChangePassword}
        error={errorsStore.getError(ErrorFormList.PASSWORD)}
      />
      <TextField
        id='repeat_password'
        name='repeat_password'
        label={t('repeat_password') as string}
        placeholder={t('repeat_password') as string}
        type='password'
        className={styles.textFieldCode}
        value={repeatedPassword}
        onChange={onChangeRepeatedPassword}
        error={errorsStore.getError(ErrorFormList.REPEAT_PASSWORD)}
      />
      <p className={styles.info}>{t('rule_for_password')}</p>
      <TextField
        id='nickname'
        name='nickname'
        label={t('think_your_nickname') as string}
        placeholder={t('enter_your_nickname') as string}
        className={styles.textFieldCode}
        value={nickName}
        onChange={onChangeNickname}
        error={errorsStore.getError(ErrorFormList.USERNAME)}
      />
      <TextField
        id='name_and_surname'
        name='name_and_surname'
        label={t('enter_your_name') as string}
        placeholder={t('enter_your_name') as string}
        className={styles.textFieldCode}
        value={name}
        onChange={onChangeName}
      />
      <Textarea
        rows={2}
        id='description'
        name='description'
        label={t('short_desc') as string}
        placeholder={t('enter_short_desc') as string}
        className={styles.textFieldCode}
        value={description}
        onChange={onChangeDescription}
      />
      <CategoryList
        onSelect={onSelectCategory}
        categories={categoriesStore.getCategories}
        selectedCategoriesIds={categoriesStore.getSelectedCategories}
      />
      <Button
        className={`${styles.formSubmit} ${styles.continueBtn}`}
        title={t('continue')}
        variant='default'
        onClick={submit}
        disabled={userStore.isPending}
      />
    </>
  );
});

export { EnterAboutSelf };

import axios, { AxiosError, isAxiosError, AxiosRequestConfig } from 'axios';
import { getJwt } from '../helpers';
import { appKey } from '../constants';
// TODO исправить import/no-cycle
// eslint-disable-next-line import/no-cycle
import { refreshToken } from './auth';

export const isServerError = isAxiosError;

export const publicApi = axios.create();

export const privateApi = axios.create();

interface FailedRequest {
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
  request: AxiosRequestConfig;
}

let isRefreshing = false;
let failedRequests: FailedRequest[] = [];

publicApi.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    const lang = localStorage.getItem(`${appKey}_lang`)?.slice(0, 2) ?? 'ru';

    if (config.url) {
      newConfig.url = `/${lang}${config.url}`;
    }

    return newConfig;
  },
  async (error: AxiosError) => Promise.reject(error),
);

privateApi.interceptors.request.use(
  (config) => {
    const jwt = getJwt();
    const newConfig = { ...config };
    const lang = localStorage.getItem(`${appKey}_lang`)?.slice(0, 2) ?? 'ru';

    if (config.url && !config.url.includes(`/${lang}/`)) {
      newConfig.url = `/${lang}${config.url}`;
    }

    if (jwt) {
      newConfig.headers.Authorization = `Bearer ${jwt}`;
    } else {
      const cancelTokenSource = axios.CancelToken.source();
      newConfig.cancelToken = cancelTokenSource.token;

      cancelTokenSource.cancel('No JWT token found');
    }

    return newConfig;
  },
  async (error: AxiosError) => Promise.reject(error),
);

privateApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      isServerError(error) &&
      typeof error.response?.data?.code === 'string' &&
      error.response.data.code === 'token_not_valid'
    ) {
      if (!isRefreshing) {
        isRefreshing = true;

        const token = localStorage.getItem(`${appKey}_jwtR`);

        if (token) {
          refreshToken(token)
            .then((res) => {
              localStorage.setItem(`${appKey}_jwt`, res.access);
              isRefreshing = false;

              failedRequests.forEach((req) => {
                if (req?.request?.headers) {
                  req.request.headers.Authorization = `Bearer ${res.access}`;
                  privateApi(req.request).then(
                    (response) => {
                      req.resolve(response);
                    },
                    (err) => err.reject(err),
                  );
                }
              });

              failedRequests = [];
            })
            .catch((err) => {
              console.error('Error while refreshing token', err);
              localStorage.removeItem(`${appKey}_jwt`);
              localStorage.removeItem(`${appKey}_jwtR`);
              window.location.replace('/login');
            });
        }
      }

      return new Promise((resolve, reject) => {
        failedRequests.push({
          resolve,
          reject,
          request: error.config as AxiosRequestConfig,
        });
      });
    }

    if (error?.response?.data?.code === 'user_not_found') {
      localStorage.removeItem(`${appKey}_jwt`);
      localStorage.removeItem(`${appKey}_jwtR`);
      window.location.replace('/login');
    }

    return Promise.reject(error);
  },
);

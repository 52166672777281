import { useMemo } from 'react';
import {Directions, Dot, Dots, Lotus, OutLotus, Paper} from 'shared/ui';
import Mobile from 'shared/assets/images/mobile.png';
import MobileEn from 'shared/assets/images/mobile_en.png';
import { useTranslate } from 'shared/hooks';
import styles from './advertise.module.scss';

function Advertise(): JSX.Element {
  const { t, language } = useTranslate();

  const renderImg = useMemo((): JSX.Element => {
    switch (language) {
      case 'ru':
        return <img className={styles.mobile} src={Mobile} alt='mobile' />;
      case 'en':
        return <img className={styles.mobile} src={MobileEn} alt='mobile' />;
      default:
        return <img className={styles.mobile} src={Mobile} alt='mobile' />;
    }
  }, [language]);

  return (
    <div className={styles.content}>
      {renderImg}
      <Paper className={styles.advertise}>
        <div className={styles.advertiseWatchVideo}>
          <div className={styles.advertiseContentCircle}>
            <Lotus />
          </div>
          <span className={styles.advertiseContentTitle1}>{t('advertise-watch-video-mp')}</span>
        </div>
        <div className={styles.containerDots}>
          <Dots className={styles.dots} />
        </div>
        <div className={styles.mobileDots}>
          <Dot />
          <Dot />
          <Dot />
        </div>
        <div className={styles.advertiseChangeLotus}>
          <div className={styles.advertiseContentCircle}>
            <Directions />
          </div>
          <span className={styles.advertiseContentTitle1}>{t('advertise-change-lotuses-mp')}</span>
        </div>
        <div className={styles.containerDots}>
          <Dots className={styles.dots} />
        </div>
        <div className={styles.mobileDots}>
          <Dot />
          <Dot />
          <Dot />
        </div>
        <div className={styles.advertiseOutLotus}>
          <div className={styles.advertiseContentCircle}>
            <OutLotus />
          </div>
          <span className={styles.advertiseContentTitle1}>{t('advertise-withdrawal-lotuses-mp')}</span>
        </div>
      </Paper>
    </div>
  );
}

export { Advertise };

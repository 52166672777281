import { Coin } from 'shared/ui';
import { declension } from 'shared/helpers';
import { useTranslate } from 'shared/hooks';
import styles from './lotus-card.module.scss';

interface LotusCardProps {
  count?: number;
  onClick?: (count?: number) => void;
  isActive?: boolean;
}

function LotusCard({ count, onClick, isActive = false }: LotusCardProps): JSX.Element {
  const { t, language } = useTranslate();
  const endings = language === 'ru' ? ['а', 'ов', ''] : ['es', 'es', ''];
  const onChange = (): void => {
    if (onClick) onClick(count);
  };

  return (
    <div
      className={`${styles.containerBalance} ${isActive ? styles.containerBalanceActive : ''}`}
      onClick={onChange}
    >
      <Coin className={styles.containerIcon} />
      <div className={styles.containerBalanceCount}>
        <span className={styles.containerBalanceCountStyle}>{count ?? '-'}</span>
        <br /> {declension(t('lotus'), endings, count ?? 0)}
      </div>
    </div>
  );
}

export { LotusCard };

import { observer } from 'mobx-react-lite';
import { Header } from 'widgets';
import { Login } from 'features/login';
import { Container, PendingContainer } from 'shared/ui';
import { useStore } from 'shared/stores';
import styles from './login-page.module.scss';

const LoginPage = observer((): JSX.Element => {
  const { userStore } = useStore();

  return (
    <PendingContainer value={userStore.isPending} className={styles.pending}>
      <Header />
      <Container>
        <Login />
      </Container>
    </PendingContainer>
  );
});

export { LoginPage };

import { Header } from 'widgets/header';
import { SendFeedback } from 'features/send-feedback';
import { Container } from 'shared/ui';

function FeedbackPage(): JSX.Element {
  return (
    <>
      <Header />
      <Container>
        <SendFeedback />
      </Container>
    </>
  );
}

export { FeedbackPage };

import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, TextField } from 'shared/ui';
import { codeRegexp, ErrorFormList } from 'shared/constants';
import { useTimer, useTranslate } from 'shared/hooks';
import { isServerError, resetPassword } from 'shared/api';
import { useStore } from 'shared/stores';
import styles from '../restore-password.module.scss';

interface EnterCodeProps {
  onSubmit: (code: string) => void | Promise<void>;
  phone: string;
  variant: 'reg' | 'restore';
}

const EnterCode = observer(({ onSubmit, phone, variant = 'reg' }: EnterCodeProps): JSX.Element => {
  const [code, setCode] = useState('');
  const { seconds, restartTimer } = useTimer(120, phone);
  const { errorsStore, userStore } = useStore();
  const { t } = useTranslate();

  const submit = async (): Promise<void> => {
    if (code.length !== 6) {
      errorsStore.setError(ErrorFormList.CODE, t('length-not-less-6'));
      return;
    }

    errorsStore.removeError(ErrorFormList.CODE);
    onSubmit(code);
  };

  const onChangeCode = (value: string): void => {
    if (codeRegexp.test(value) && value.length <= 6) {
      setCode(value);
    }
  };

  const resendCode = async (): Promise<void> => {
    userStore.setIsPending(true);

    try {
      await resetPassword(phone);
      errorsStore.removeError(ErrorFormList.RESTORE_PASSWORD);
    } catch (e) {
      if (isServerError(e) && typeof e?.response?.data?.detail === 'string') {
        if (variant === 'restore') {
          errorsStore.setError(ErrorFormList.RESTORE_PASSWORD, e.response.data.detail);
        }
      }

      if (isServerError(e) && e?.response?.data?.code instanceof Array) {
        errorsStore.setError(ErrorFormList.CODE, e.response.data.code);
      }

      userStore.setIsPending(false);
      return;
    }

    userStore.setIsPending(false);
    restartTimer();
  };

  return (
    <>
      <TextField
        id='phone'
        name='phone'
        label={t('your-phone-label') as string}
        placeholder={t('enter-phone-placeholder') as string}
        value={phone}
        disabled
      />
      <TextField
        className={styles.textFieldCode}
        id='code'
        name='code'
        label={t('enter-code') as string}
        placeholder={t('enter-code') as string}
        value={code}
        onChange={onChangeCode}
        error={errorsStore.getError(ErrorFormList.CODE)}
      />
      <div className={styles.timer}>
        {seconds > 0 && (
          <p className={styles.timerInfo}>
            {seconds} {t('sec')}
          </p>
        )}
        <Button
          className={styles.timerBtn}
          disabled={seconds > 0}
          variant='outline'
          title={t('send-code-again')}
          onClick={resendCode}
        />
      </div>
      <Button
        className={`${styles.formSubmit} ${styles.continueBtn}`}
        title={t('continue')}
        variant='default'
        onClick={submit}
        disabled={userStore.isPending}
      />
    </>
  );
});

export { EnterCode };

import { CurrenciesAnnotation } from 'shared/models';

function getCurrencySign(currency: CurrenciesAnnotation): string {
  switch (currency) {
    case 'Etherium':
      return 'ETH';
    case 'USDT':
      return 'USDT';
    case 'Bitcoin':
      return 'BTC';
    default:
      return 'BTC';
  }
}

export { getCurrencySign };

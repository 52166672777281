import { FetchWithdrawalList } from 'shared/api';
import { formatDate } from 'shared/helpers';
import { useTranslate } from 'shared/hooks';
import { RequestStatus } from './request-status';
import styles from './withdrawal-requets-mobile.module.scss';

interface WithdrawalRequestMobileProps {
  request: FetchWithdrawalList;
}

function WithdrawalRequestMobile({ request }: WithdrawalRequestMobileProps): JSX.Element {
  const { t } = useTranslate();
  return (
    <div className={styles.container}>
      <div className={styles.containerRow}>
        <p className={styles.containerTitle}>{t('request_date')}</p>
        <span className={styles.containerValue}>{formatDate(request.created_at)}</span>
      </div>
      <div className={styles.containerRow}>
        <p className={styles.containerTitle}>{t('amount_lotuses')}</p>
        <span className={styles.containerValue}>{request.amount}</span>
      </div>
      <div className={styles.containerRow}>
        <p className={`${styles.containerTitle} ${styles.containerRequestStatus}`}>
          {t('request_status')}
        </p>
        <RequestStatus status={request.status} />
      </div>
      <div className={`${styles.containerRow} ${styles.containerWallet}`}>
        <p className={styles.containerTitle}>{t('e_wallet_number')}</p>
        <span className={`${styles.containerWalletName} ${styles.containerValue}`}>
          {request.wallet_address}
        </span>
      </div>
    </div>
  );
}

export { WithdrawalRequestMobile };

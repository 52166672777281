import { Currency, Rates } from '../models';

function convertLotusesToMoney(amount: number, currency: Currency, rates: Rates): string {
  switch (currency) {
    case Currency.RUB:
      return `${amount * Number(rates.rub)}`;
    case Currency.BTC:
      return `${(amount * Number(rates.btc)).toFixed(10)}`;
    case Currency.USDT:
      return `${(amount * Number(rates.usdt)).toFixed(10)}`;
    case Currency.ETH:
      return `${(amount * Number(rates.eth)).toFixed(10)}`;
    default:
      return `${amount * Number(rates.rub)}`;
  }
}

export { convertLotusesToMoney };

import { observer } from 'mobx-react-lite';
import { Header } from 'widgets';
import { EditUserProfile } from 'features/edit-user-profile';
import { Container, Paper, PendingContainer } from 'shared/ui';
import { ROUTES } from 'shared/constants';
import { useStore } from 'shared/stores';
import styles from './profile-page.module.scss';

const ProfilePage = observer((): JSX.Element => {
  const { userStore, categoriesStore } = useStore();

  const closeFromPaper = (): string => {
    categoriesStore.clearSelectedCategories();
    userStore.setUser(userStore.user);
    return ROUTES.lk.to;
  };

  return (
    <>
      <Header />
      <Container className={styles.content}>
        <PendingContainer value={userStore.isPending}>
          <Paper className={styles.container} closeTo={closeFromPaper()}>
            <EditUserProfile />
          </Paper>
        </PendingContainer>
      </Container>
    </>
  );
});

export { ProfilePage };

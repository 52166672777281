import { AppleDownload, GoogleDownload, RuDownload, NashDownload } from 'shared/ui/icons';
import { useTranslate } from 'shared/hooks';
import styles from './download-app-links.module.scss';

interface DownloadAppLinksProps {
  className?: string;
}

function DownloadAppLinks({ className = '' }: DownloadAppLinksProps): JSX.Element {
  const { language } = useTranslate();
  return (
    <div className={`${styles.container} ${className}`}>
      <a
        href='https://apps.apple.com/ru/app/ditl/id1591242197'
        target='_blank'
        referrerPolicy='no-referrer'
        rel='noreferrer'
      >
        <AppleDownload lang={language} />
      </a>
      <a
        href='https://play.google.com/store/apps/details?id=com.dnatheteam.ditlandroid'
        target='_blank'
        referrerPolicy='no-referrer'
        rel='noreferrer'
      >
        <GoogleDownload lang={language} />
      </a>
      {(language === 'ru' || language === 'ru-RU') && (
        <>
          <a href='https://apps.rustore.ru/app/com.dnatheteam.ditlandroid' target='_blank' referrerPolicy='no-referrer' rel="noreferrer">
            <RuDownload />
          </a>
          <a
            href='https://store.nashstore.ru/store/62b1e249fb3ed3efa0ec78fe'
            target='_blank'
            referrerPolicy='no-referrer'
            rel='noreferrer'
          >
            <NashDownload />
          </a>
        </>
      )}
    </div>
  );
}

export { DownloadAppLinks };

import { observer } from 'mobx-react-lite';
import { Checkbox } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import { useWithdrawalStore } from '../hooks';
import styles from '../withdrawal-balance.module.scss';

const UserAgreements = observer(() => {
  const withdrawalStore = useWithdrawalStore();
  const { t } = useTranslate();

  const onChangeUserAgree = (value: boolean): void => {
    withdrawalStore.setUserAgreements(value);
  };

  return (
    <div className={styles.agreements}>
      <Checkbox
        value={withdrawalStore.userAgreements}
        onChange={onChangeUserAgree}
        id='agreements'
      />
      <p>
        {t('by_click_i_accept2')}
        <a
          href='/docs/Terms_of_Use_Ditl.pdf'
          target='_blank'
          referrerPolicy='no-referrer'
          rel='noreferrer'
        >
          {t('terms')}
        </a>
      </p>
    </div>
  );
});

export { UserAgreements };

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from 'widgets';
import { Accept, Button, Container, Error, Paper, PendingContainer } from 'shared/ui';
import { appKey, ROUTES } from 'shared/constants';
import { useTranslate } from 'shared/hooks';
import { fetchBuyStatus } from 'shared/api';
import { BuyStatuses } from 'shared/models';
import styles from './success-replenishment-page.module.scss';

function SuccessReplenishmentPage(): JSX.Element {
  const navigator = useNavigate();
  const { t } = useTranslate();
  const searchParams = new URLSearchParams(window.location.search);
  const paymentToken = searchParams.get('payment_token');
  const [buyStatus, setBuyStatus] = useState<BuyStatuses>('cancelled');
  const intervalIdRef = useRef<NodeJS.Timer | null>(null);
  const [amountLotuses, setAmountLotuses] = useState(0);

  useEffect(() => {
    if (paymentToken) {
      fetchBuyStatus(paymentToken).then((res) => {
        setBuyStatus(res.status);
        setAmountLotuses(res.lotus_count);
      });
    }
  }, [paymentToken]);

  useEffect(() => {
    if (paymentToken) {
      intervalIdRef.current = setInterval(() => {
        fetchBuyStatus(paymentToken).then((res) => {
          setBuyStatus(res.status);
          setAmountLotuses(res.lotus_count);
        });
      }, 3000);
    }

    return () => {
      if (intervalIdRef.current !== null) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [paymentToken]);

  useEffect(() => {
    if (buyStatus !== 'pending' && intervalIdRef.current !== null) {
      clearInterval(intervalIdRef.current);
    }
  }, [buyStatus]);

  const finishHandler = (): void => {
    localStorage.removeItem(`${appKey}_amount_lotuses`);
    navigator(ROUTES.lk.to);
  };

  return (
    <>
      <Header />
      <Container className={styles.container}>
        <Paper className={styles.paper} closeTo={ROUTES.lk.to}>
          <PendingContainer value={buyStatus === 'pending'}>
            {buyStatus === 'succeeded' && (
              <>
                <i className={styles.acceptIcon}>
                  <Accept />
                </i>
                <h3 className={styles.title}>{t('successfully_topped_up')}</h3>
                <p className={styles.description}>
                  {amountLotuses} {t('lotus_sent')}
                </p>
                <Button onClick={finishHandler} className={styles.finish} title={t('finish')} />
              </>
            )}
            {buyStatus === 'cancelled' ||
              (buyStatus === 'init' && (
                <>
                  <i className={styles.errorIcon}>
                    <Error />
                  </i>
                  <h3 className={styles.title}>{t('error_buy_title')}</h3>
                  <p className={styles.description}>{t('error_buy_desc')}</p>
                  <Button onClick={finishHandler} className={styles.finish} title={t('close')} />
                </>
              ))}
          </PendingContainer>
        </Paper>
      </Container>
    </>
  );
}

export { SuccessReplenishmentPage };

import { useState } from 'react';
import styles from './radio.module.scss';

export interface RadioOnChange { name: string; checked: boolean }

interface RadioProps {
  id: string;
  label: string;
  onChange?: ({ name, checked }: RadioOnChange) => void;
  isChecked?: boolean;
}

function Radio({ id, label, onChange, isChecked = false }: RadioProps): JSX.Element {
  const [checked, setChecked] = useState(isChecked);

  const setCheckedRadio =
    (name: string): (() => void) =>
    (): void => {
      setChecked(!checked);
      if (onChange) onChange({ checked, name });
    };

  return (
    <div className={styles.container} onClick={setCheckedRadio(label)}>
      <label className={styles.label} htmlFor={id}>
        <input defaultChecked={checked} type='radio' className={styles.input} id={id} />
        {isChecked && <i className={styles.labelIcon} />}
      </label>
      <span>{label}</span>
    </div>
  );
}

export { Radio };

import { ReactComponent as GearIcon } from '../../../assets/icons/gear.svg';

interface GearProps {
  className?: string;
}

function Gear({ className = '' }: GearProps): JSX.Element {
  return <GearIcon className={className} />;
}

export { Gear };

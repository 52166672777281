import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { CloseAlt } from 'shared/ui';
import styles from './paper.module.scss';

interface PaperProps {
  children: ReactNode;
  className?: string;
  closeTo?: string;
  onClick?: () => void;
}

function Paper({ children, className = '', closeTo, onClick }: PaperProps): JSX.Element {
  return (
    <div className={`${styles.container} ${className}`} onClick={onClick}>
      {closeTo && (
        <Link className={styles.close} to={closeTo}>
          <CloseAlt />
        </Link>
      )}
      {children}
    </div>
  );
}

export { Paper };

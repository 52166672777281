import { useState } from 'react';
import { CloseAlt, Link as LinkIcon } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import styles from '../withdrawal-balance.module.scss';

function AboutEWalletPopup(): JSX.Element {
  const { t } = useTranslate();
  const [isShow, setIsShow] = useState(false);

  const togglePopup = (): void => {
    setIsShow(!isShow);
  };

  return (
    <div className={styles.anotherValue} onClick={togglePopup}>
      <LinkIcon /> {t('what_cryptocurrency_wallet')}
      {isShow && (
        <div className={styles.eWalletPopup}>
          <i className={styles.close}>
            <CloseAlt onClick={togglePopup} />
          </i>
          {t('what_is_ewallet')}
          <div className={styles.source}>
            {t('source')}:{' '}
            <a
              href='https://www.banki.ru'
              target='_blank'
              referrerPolicy='no-referrer'
              rel='noreferrer'
            >
              https://www.banki.ru
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export { AboutEWalletPopup };

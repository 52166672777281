import { ReactComponent as CoinIcon } from '../../../assets/icons/coin.svg';

interface CoinProps {
  className?: string;
}

function Coin({ className = '' }: CoinProps): JSX.Element {
  return <CoinIcon className={className} />;
}

export { Coin };

import { Header } from 'widgets';
import { Register } from 'features/register';
import { Container } from 'shared/ui';

function RegisterPage(): JSX.Element {
  return (
    <>
      <Header />
      <Container>
        <Register />
      </Container>
    </>
  );
}

export { RegisterPage };

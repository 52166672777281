import { observer } from 'mobx-react-lite';
import { useStore } from 'shared/stores';
import { useTranslate } from 'shared/hooks';
import { getCurrency } from '../helpers';
import { useWithdrawalStore } from '../hooks';
import styles from '../withdrawal-balance.module.scss';

const CurrentRate = observer((): JSX.Element => {
  const { t } = useTranslate();
  const { commonStore } = useStore();
  const rates = commonStore.getRates;
  const withdrawalStore = useWithdrawalStore();

  return (
    <span className={styles.rate}>
      1 {t('lotus')} = {getCurrency(rates, withdrawalStore.activeCurrency)}
    </span>
  );
});

export { CurrentRate };

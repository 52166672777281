import { ReactComponent as AppleDownloadIcon } from '../../../assets/icons/apple-download.svg';
import { ReactComponent as AppleDownloadEnIcon } from '../../../assets/icons/apple-download-en.svg';

interface AppleDownloadProps {
  className?: string;
  lang?: string;
}

function AppleDownload({ className = '', lang = 'ru' }: AppleDownloadProps): JSX.Element {
  const renderIcon = (): JSX.Element => {
    switch (lang) {
      case 'ru':
        return <AppleDownloadIcon className={className} />;
      case 'en':
        return <AppleDownloadEnIcon className={className} />;
      default:
        return <AppleDownloadIcon className={className} />;
    }
  };

  return renderIcon();
}

export { AppleDownload };

import { observer } from 'mobx-react-lite';
import { Coin, TextField } from 'shared/ui';
import { convertLotusesToMoney } from 'shared/helpers';
import { useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';
import { getCurrencySign, normalizeCurrency } from '../helpers';
import { useWithdrawalStore } from '../hooks';
import styles from '../withdrawal-balance.module.scss';

const EnterAmountAndConvertToCurrency = observer((): JSX.Element => {
  const { t } = useTranslate();
  const { userStore, commonStore } = useStore();
  const rates = commonStore.getRates;
  const withdrawalStore = useWithdrawalStore();

  const onChangeAmountLotuses = (value: string): void => {
    if (userStore.user.lotus_count >= Number(value))
      withdrawalStore.setAmountLotuses(Number(value));
  };

  return (
    <div className={styles.convert}>
      <TextField
        className={styles.convertTransactionTextField}
        id='lotus-count'
        name='lotus-count'
        label={t('number_lotuses_transfer') as string}
        icon={<Coin className={styles.coinIcon} />}
        onChange={onChangeAmountLotuses}
        value={String(withdrawalStore.amountLotuses)}
      />
      <TextField
        className={styles.convertTransactionTextField}
        inputClassName={styles.textFieldDisabled}
        id='user-will-get'
        name='user-will-get'
        label={t('you_will_get') as string}
        disabled
        icon={<i className={styles.btc}>{getCurrencySign(withdrawalStore.activeCurrency)}</i>}
        value={
          rates
            ? convertLotusesToMoney(
                Number(withdrawalStore.amountLotuses),
                normalizeCurrency(withdrawalStore.activeCurrency),
                rates,
              )
            : '0'
        }
      />
    </div>
  );
});

export { EnterAmountAndConvertToCurrency };

import { useEffect, useRef, useState } from 'react';
import { Arrow } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';
import { Languages } from './types';
import styles from './change-locale.module.scss';

function ChangeLocale(): JSX.Element {
  const { commonStore } = useStore();
  const { changeLanguage, language } = useTranslate();
  const [isShowLocaleMenu, setIsShowLocaleMenu] = useState(false);
  const localeMenuRef = useRef<HTMLDivElement>(null);

  const [activeLanguage, setActiveLanguage] = useState<Languages>(
    language.toUpperCase().slice(0, 2) as Languages,
  );

  useEffect(() => {
    commonStore.setLang(language.slice(0, 2));
  }, [commonStore, language]);

  const onChangeLanguage = (lang: Languages) => () => {
    const loweredLang = lang.toLowerCase();
    setActiveLanguage(lang);
    changeLanguage(loweredLang);
    commonStore.setLang(loweredLang);
  };

  const toggleLocaleMenu = (): void => {
    setIsShowLocaleMenu(!isShowLocaleMenu);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (localeMenuRef.current && !localeMenuRef.current.contains(event.target as Node)) {
      setIsShowLocaleMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.locale} onClick={toggleLocaleMenu} ref={localeMenuRef}>
      <span>{activeLanguage.toUpperCase()}</span>
      <Arrow className={styles.localeIcon} />
      {isShowLocaleMenu && (
        <div className={styles.localeMenu}>
          <span onClick={onChangeLanguage('RU')}>RU</span>
          <span onClick={onChangeLanguage('EN')}>EN</span>
        </div>
      )}
    </div>
  );
}

export { ChangeLocale };

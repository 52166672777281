import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { Routing } from 'pages';
import { fetchUser, fetchUserCategories } from 'shared/api';
import { useStore } from 'shared/stores';
import { useTranslate } from 'shared/hooks';
import 'react-phone-input-2/lib/style.css'
import './app.module.scss';

const App = observer((): JSX.Element => {
  const { userStore, commonStore, categoriesStore } = useStore();
  const lang = commonStore.getLang;
  const { t } = useTranslate();

  useEffect(() => {
    userStore.setIsPending(true);

    if (Object.keys(userStore.user).length === 0) {
      fetchUser()
        .then((res) => {
          userStore.setUser(res);
        })
        .finally(() => {
          userStore.setIsPending(false);
        });
    }
  }, [categoriesStore, userStore]);

  useEffect(() => {
    fetchUserCategories().then((res) => {
      categoriesStore.setCategories(res);
    });
  }, [categoriesStore, lang]);

  return (
    <>
      <Helmet>
        <meta name='description' content={t('meta_description') as string} />
      </Helmet>
      <Routing />
    </>
  );
});

export { App };

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { validateEmail, validatePhone } from 'shared/helpers';
import { Button, TextField } from 'shared/ui';
import { appKey, ErrorFormList, ROUTES } from 'shared/constants';
import { auth, fetchUser, fetchUserCategories, isServerError } from 'shared/api';
import { useStore } from 'shared/stores';
import { useTranslate } from 'shared/hooks';
import styles from './auth.module.scss';

const Auth = observer((): JSX.Element => {
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const navigator = useNavigate();
  const { errorsStore, userStore, categoriesStore } = useStore();
  const { t } = useTranslate();

  const onChangeAccount = (value: string): void => {
    setAccount(value);
  };

  const onChangePassword = (value: string): void => {
    setPassword(value);
  };

  const onSubmit = async (): Promise<void> => {
    userStore.setIsPending(true);

    if (!account) {
      errorsStore.setError(ErrorFormList.ACCOUNT, t('require-field'));
      userStore.setIsPending(false);
      return;
    }

    if (!validateEmail(account) && !validatePhone(account)) {
      errorsStore.setError(ErrorFormList.ACCOUNT, t('wrong-phone-or-email'));
      userStore.setIsPending(false);
      return;
    }

    errorsStore.removeError(ErrorFormList.ACCOUNT);

    if (!password) {
      errorsStore.setError(ErrorFormList.PASSWORD, t('require-field'));
      userStore.setIsPending(false);
      return;
    }

    errorsStore.reset();

    try {
      const authRes = await auth(account, password);
      localStorage.setItem(`${appKey}_jwt`, authRes.access);
      localStorage.setItem(`${appKey}_jwtR`, authRes.refresh);
    } catch (e) {
      if (isServerError(e) && typeof e?.response?.data?.detail === 'string') {
        errorsStore.setError(ErrorFormList.AUTH, e.response?.data?.detail);
      }
      userStore.setIsPending(false);
      return;
    }

    const resUser = await fetchUser();
    userStore.setUser(resUser);

    const categoriesRes = await fetchUserCategories();
    categoriesStore.setCategories(categoriesRes);

    userStore.setIsPending(false);
    navigator(ROUTES.lk.to);
  };

  return (
    <div className={styles.form}>
      <div className={styles.formTextfields}>
        <TextField
          id='account'
          name='account'
          label={t('account-label') as string}
          placeholder={t('account-placeholder') as string}
          value={account}
          onChange={onChangeAccount}
          error={errorsStore.getError(ErrorFormList.ACCOUNT)}
        />
        <TextField
          value={password}
          onChange={onChangePassword}
          id='password'
          name='password'
          label={t('password-label') as string}
          type='password'
          placeholder={t('password-placeholder') as string}
          error={errorsStore.getError(ErrorFormList.PASSWORD)}
        />
      </div>
      <Link className={styles.formForgetPsw} to={ROUTES.forgetPassword.to}>
        {t('forget-password')}
      </Link>
      <Button
        disabled={userStore.isPending}
        onClick={onSubmit}
        className={styles.formSubmit}
        title={t('log-in')}
        variant='default'
      />
    </div>
  );
});

export { Auth };

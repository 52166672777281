import { ReactComponent as NashDownloadIcon } from '../../../assets/icons/nash-download.svg';

interface NashDownloadProps {
  className?: string;
}

function NashDownload({ className = '' }: NashDownloadProps): JSX.Element {
  return <NashDownloadIcon className={className} />;
}

export { NashDownload };

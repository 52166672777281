import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Radio, RadioOnChange } from 'shared/ui';
import { Currencies, CurrenciesAnnotation } from 'shared/models';
import { useTranslate } from 'shared/hooks';
import { useWithdrawalStore } from '../hooks';
import styles from '../withdrawal-balance.module.scss';

const radios: Currencies = ['Bitcoin', 'USDT', 'Etherium'];

const ChooseCurrency = observer((): JSX.Element => {
  const { t } = useTranslate();
  const withdrawalStore = useWithdrawalStore();

  const setActiveValueRadio = useCallback(
    ({ name }: RadioOnChange) => {
      withdrawalStore.setActiveCurrency(name as CurrenciesAnnotation);
    },
    [withdrawalStore],
  );

  const renderRadios = useMemo(
    () =>
      radios.map((r) => (
        <Radio
          isChecked={withdrawalStore.activeCurrency === r}
          onChange={setActiveValueRadio}
          key={r}
          id={r.toLowerCase()}
          label={r}
        />
      )),
    [setActiveValueRadio, withdrawalStore.activeCurrency],
  );

  return (
    <div className={styles.radios}>
      <p>{t('select_type_e_wallet')}</p>
      <div className={styles.radiosContent}>{renderRadios}</div>
    </div>
  );
});

export { ChooseCurrency };

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Header, RequestsHistory } from 'widgets';
import { ReplenishmentBalance } from 'features/replenishment-balance';
import { WithdrawalBalance } from 'features/withdrawal-balance';
import { Container, Paper } from 'shared/ui';
import { Tab, useTabs, useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';
import { fetchRates } from 'shared/api';
import { SharePopup } from 'pages/balance-page/components';
import styles from './balance-page.module.scss';

const BalancePage = observer((): JSX.Element => {
  const { t } = useTranslate();
  const tabs: Tab[] = [
    { title: t('replenishment'), content: <ReplenishmentBalance /> },
    { title: t('withdrawal'), content: <WithdrawalBalance /> },
    { title: t('share'), content: null },
    { title: t('requests_history'), content: <RequestsHistory /> },
  ];
  const { commonStore } = useStore();
  const location = useLocation();
  const defaultTab = location?.state?.tab ?? 0;
  const { renderTabs, activeTab, isPopup } = useTabs({ tabs, defaultTab: tabs[defaultTab] });

  useEffect(() => {
    if (commonStore.getRates === null) {
      fetchRates().then((res) => {
        commonStore.setRates(res);
      });
    }
  }, [commonStore]);

  return (
    <>
      <Header />
      <Container className={styles.container}>
        {isPopup && <SharePopup />}
        {renderTabs}
        <Paper className={styles.paper}>{activeTab.content}</Paper>
      </Container>
    </>
  );
});

export { BalancePage };

import { privateApi, publicApi } from './api';
import { usersMeUri, commonUri } from '../constants';
import { Category, Id } from '../models';

interface GetCategoriesResponse {
  count: number;
  results: Category[];
}

const uri = 'categories/';

export async function getCategories(): Promise<GetCategoriesResponse> {
  const res = await publicApi.get<GetCategoriesResponse>(`${commonUri}/${uri}`);
  return res.data;
}

export async function saveCategories(categoryIds: Id[]): Promise<Category[]> {
  const res = await privateApi.post<Category[]>(`${usersMeUri}${uri}`, { categories_id: categoryIds });
  return res.data;
}

export async function fetchUserCategories(): Promise<Category[]> {
  const res = await privateApi.get<Category[]>(`${usersMeUri}${uri}`);
  return res.data;
}

import { useCallback, useMemo, useState } from 'react';
import { LotusCard } from 'entities/lotus';
import { useStore } from 'shared/stores';
import styles from '../replenishment-balance.module.scss';

function LotusCards(): JSX.Element {
  const [activeLotusCard, setActiveLotusCard] = useState<number | undefined>(undefined);
  const { replenishmentBalanceStore } = useStore();

  const selectLotusCard = useCallback(
    (count?: number) => {
      if (typeof count === 'number') replenishmentBalanceStore.setAmountLotuses(String(count));
      setActiveLotusCard(count);
    },
    [],
  );

  const renderLotusCards = useMemo(
    (): JSX.Element[] =>
      [5, 25, 50, 250, 500, undefined].map((l) => (
        <LotusCard
          isActive={activeLotusCard === l}
          onClick={selectLotusCard}
          key={l ?? 0}
          count={l}
        />
      )),
    [activeLotusCard, selectLotusCard],
  );

  return <div className={styles.lotuses}>{renderLotusCards}</div>;
}

export { LotusCards };

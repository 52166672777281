import { convertLotusesToMoney } from 'shared/helpers';
import { Currency, Rates } from 'shared/models';

function getCurrency(rates: Rates | null, value: string): string | null {
  if (rates) {
    switch (value) {
      case 'Bitcoin':
        return `${convertLotusesToMoney(1, Currency.BTC, rates)} BTC`;
      case 'USDT':
        return `${convertLotusesToMoney(1, Currency.USDT, rates)} USDT`;
      case 'Etherium':
        return `${convertLotusesToMoney(1, Currency.ETH, rates)} ETH`;
      default:
        return convertLotusesToMoney(1, Currency.BTC, rates);
    }
  }

  return null;
}

export { getCurrency };

import { ReactComponent as MinusIcon } from '../../../assets/icons/minus.svg';

interface MinusProps {
  className?: string;
  onClick?: () => void;
}

function Minus({ className = '', onClick }: MinusProps): JSX.Element {
  return <MinusIcon className={className} onClick={onClick} />;
}

export { Minus };

import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ErrorFormList, ROUTES } from 'shared/constants';
import { ErrorMsg, Paper } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';
import { Auth } from './components';
import styles from './login.module.scss';

const Login = observer((): JSX.Element => {
  const { errorsStore } = useStore();
  const authError = errorsStore.getError(ErrorFormList.AUTH);
  const { t } = useTranslate();

  const closeErrorMsg = (): void => {
    errorsStore.removeError(ErrorFormList.AUTH);
  };

  return (
    <Paper className={styles.container} closeTo={ROUTES.main.to}>
      <div className={styles.content}>
        <h3 className={styles.title}>{t('login-to-account')}</h3>
        {authError && (
          <ErrorMsg className={styles.errorMsg} error={authError} onClose={closeErrorMsg} />
        )}
        <Auth />
        {t('have-not-account')}{' '}
        <Link className={styles.register} to={ROUTES.register.to}>
          <span>{t('sign-up')}</span>
        </Link>
      </div>
    </Paper>
  );
});

export { Login };

import { useState, KeyboardEvent } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, TextField } from 'shared/ui';
import { ErrorFormList, ROUTES } from 'shared/constants';
import { useStore } from 'shared/stores';
import { formatPhoneNumber, removeNonPlusChars, validatePhone } from 'shared/helpers';
import { useTranslate, useTrans } from 'shared/hooks';
import styles from '../register.module.scss';

interface EnterPhoneProps {
  onSubmit: (phone: string) => void | Promise<void>;
}

const EnterPhone = observer(({ onSubmit }: EnterPhoneProps): JSX.Element => {
  const [phone, setPhone] = useState('');
  const { errorsStore, userStore } = useStore();
  const { t } = useTranslate();
  const Trans = useTrans();

  const onChange = (value: string): void => {
    setPhone(formatPhoneNumber(value));
  };

  const submit = (): void => {
    if (!validatePhone(removeNonPlusChars(phone))) {
      errorsStore.setError(ErrorFormList.MOBILE_PHONE, t('wrong-format-phone'));
      return;
    }

    errorsStore.removeError(ErrorFormList.MOBILE_PHONE);
    onSubmit(phone);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Backspace') {
      setPhone(formatPhoneNumber(phone));
    }
  };

  return (
    <>
      <TextField
        variant='phone'
        id='phone'
        name='phone'
        label={t('your-phone-label') as string}
        placeholder='+X XXX XXX XX XX'
        error={errorsStore.getError(ErrorFormList.MOBILE_PHONE)}
        onChange={onChange}
        value={phone}
        onKeyDown={handleKeyDown}
      />
      <p className={styles.info}>
        {t('continue_you_accept')}
        <a
          href='/docs/Terms_of_Use_Ditl.pdf'
          target='_blank'
          referrerPolicy='no-referrer'
          rel='noreferrer'
        >
          {t('terms')}
        </a>
        {t('and_approve_you_read')}
        <a
          href='/docs/Privacy_Policy_Ditl.pdf'
          target='_blank'
          referrerPolicy='no-referrer'
          rel='noreferrer'
        >
          {t('privacy')}
        </a>{' '}
        Diamond in the Lotus
      </p>
      <Button
        className={`${styles.formSubmit} ${styles.sendCode}`}
        title={t('send-code')}
        variant='default'
        onClick={submit}
        disabled={userStore.isPending}
      />
      <div className={styles.register}>
        {t('already_registered')}
        <Link to={ROUTES.login.to}>
          <Trans i18nKey='is_registered'>
            <span>Войти в аккаунт</span>
          </Trans>
        </Link>
      </div>
    </>
  );
});

export { EnterPhone };

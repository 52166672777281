import styles from './button.module.scss';

export type ButtonVariant = 'rounded' | 'default' | 'outline';
export type ButtonIconPos = 'left' | 'right';

interface ButtonProps {
  title: string;
  onClick?: () => void | Promise<void>;
  variant?: ButtonVariant;
  className?: string;
  icon?: JSX.Element;
  iconsPos?: ButtonIconPos;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

function Button({
  title,
  onClick,
  variant = 'default',
  className = '',
  icon,
  iconsPos = 'right',
  disabled = false,
  type = 'button',
}: ButtonProps): JSX.Element {
  const getClassNameBtn = (): string => {
    switch (variant) {
      case 'rounded':
        return styles.buttonRounded;
      case 'default':
        return styles.buttonDefault;
      case 'outline':
        return styles.buttonOutLine;
      default:
        return styles.buttonDefault;
    }
  };

  return (
    <button
      className={`${styles.button} ${getClassNameBtn()} ${className} ${styles.buttonIcon} ${
        iconsPos === 'left' ? styles.buttonIconPosLeft : styles.buttonIconPosRight
      } ${disabled ? styles.buttonDisabled : ''}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      title={title}
    >
      {title}
      {icon}
    </button>
  );
}

export { Button };

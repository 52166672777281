import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';

interface ArrowProps {
  className?: string;
}

function Arrow({ className = '' }: ArrowProps): JSX.Element {
  return <ArrowIcon className={className} />;
}

export { Arrow };

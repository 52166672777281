import { makeAutoObservable } from 'mobx';
import { Rates } from '../models';

interface ICommonStore {
  getLang: string;
  setLang: (lang: string) => void;
  setRates: (rates: Rates) => void;
  getRates: Rates | null;
}

class CommonStore implements ICommonStore {
  private lang: string = 'ru';

  private rates: Rates | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get getLang(): string {
    return this.lang;
  }

  get getRates(): Rates | null {
    return this.rates;
  }

  setLang(lang: string): void {
    this.lang = lang;
  }

  setRates(rates: Rates): void {
    this.rates = rates;
  }
}

export type { ICommonStore };
export { CommonStore };

export interface Rates {
  rub: 'string';
  usdt: 'string';
  btc: 'string';
  eth: 'string';
}

export enum Currency {
  RUB = 'rub',
  USDT = 'usdt',
  BTC = 'btc',
  ETH = 'eth',
}

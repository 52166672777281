import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, TextField } from 'shared/ui';
import { ErrorFormList } from 'shared/constants';
import { useStore } from 'shared/stores';
import { useTranslate } from 'shared/hooks';
import { validatePhone, validatePhoneLastChar } from 'shared/helpers';
import styles from '../restore-password.module.scss';

interface EnterPhoneProps {
  onSubmit: (phone: string) => void | Promise<void>;
}

const EnterPhone = observer(({ onSubmit }: EnterPhoneProps): JSX.Element => {
  const [phone, setPhone] = useState('');
  const { errorsStore, userStore } = useStore();
  const { t } = useTranslate();

  const onChange = (value: string): void => {
    if (validatePhoneLastChar(value)) {
      let newPhone = value;

      if (value.length > 0 && value[0] !== '+') {
        newPhone = `+${value}`;
      }

      setPhone(newPhone);
    }
  };

  const submit = (): void => {
    if (!validatePhone(phone)) {
      errorsStore.setError(ErrorFormList.MOBILE_PHONE, t('wrong-format-phone'));
      return;
    }

    errorsStore.removeError(ErrorFormList.MOBILE_PHONE);
    onSubmit(phone);
  };

  return (
    <>
      <TextField
        id='phone'
        name='phone'
        label={t('your-phone-label') as string}
        placeholder='+7 XXX XXX XX XX'
        error={errorsStore.getError(ErrorFormList.MOBILE_PHONE)}
        onChange={onChange}
        value={phone}
        variant='phone'
      />
      <Button
        className={`${styles.formSubmit} ${styles.sendCode}`}
        title={t('send-code')}
        variant='default'
        onClick={submit}
        disabled={userStore.isPending}
      />
    </>
  );
});

export { EnterPhone };

import { observer } from 'mobx-react-lite';
import { Header, LkInfo } from 'widgets';
import { Container, Paper, PendingContainer } from 'shared/ui';
import { useStore } from 'shared/stores';
import styles from './lk-page.module.scss';

const LkPage = observer((): JSX.Element => {
  const { userStore } = useStore();
  return (
    <>
      <Header />
      <Container className={styles.container}>
        <PendingContainer value={userStore.isPending}>
          <Paper className={styles.paper}>
            <LkInfo />
          </Paper>
        </PendingContainer>
      </Container>
    </>
  );
});

export { LkPage };

import { makeAutoObservable, observable } from 'mobx';

interface IErrorsStore {
  setError: (error: string, msg: string) => void;
  removeError: (error: string) => void;
  getError: (error: string) => string | undefined;
  removeErrors: (errors: string[]) => void;
  reset: () => void;
}

class ErrorsStore implements IErrorsStore {
  private readonly errors = observable.map<string, string>();

  constructor() {
    makeAutoObservable(this);
  }

  getError(error: string): string | undefined {
    return this.errors.get(error);
  }

  setError(error: string, msg: string): void {
    this.errors.set(error, msg);
  }

  removeError(error: string): void {
    if (this.errors.has(error)) {
      this.errors.delete(error);
    }
  }

  removeErrors(errors: string[]): void {
    errors.forEach((error) => {
      if (this.errors.has(error)) {
        this.errors.delete(error);
      }
    });
  }

  reset(): void {
    this.errors.clear();
  }
}

export type { IErrorsStore };
export { ErrorsStore };

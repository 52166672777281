import { publicApi } from './api';
import { Token, User } from '../models';
import { authUri } from '../constants';

export interface RegisterUserParams {
  token: string;
  password: string;
}

const registerUri = authUri;

export async function sendValidationCode(mobile_phone: string): Promise<unknown> {
  // если у нас уже есть в локал сторейдже такой телефон и счетчик кода не протух, скипаем запрос, чтобы не спамить
  if(mobile_phone?.includes(localStorage.getItem('ditl-lk-code-phone') ?? '') && Math.floor((Number(localStorage.getItem('ditl-lk-code-counter')) - new Date().getTime()) / 1000) > 0) {
    return false;
  }

  const res = await publicApi.post<unknown>(`${registerUri}user_validation_send/`, {
    mobile_phone,
  });
  return res.data;
}

export interface ResponseConfirmValidationCode {
  token: Token;
}

export async function confirmValidationCode(
  code: string,
  mobile_phone: string,
): Promise<ResponseConfirmValidationCode> {
  const res = await publicApi.post<ResponseConfirmValidationCode>(
    `${registerUri}user_validation_confirm/`,
    {
      code,
      mobile_phone,
    },
  );
  return res.data;
}

export async function registerUser(params: RegisterUserParams | User): Promise<User> {
  const res = await publicApi.post<User>(`${registerUri}`, params);
  return res.data;
}

export async function validateUserName(username: string): Promise<void> {
  await publicApi.post(`${registerUri}check_username/`, { username });
}

export async function validatePassword(password: string): Promise<unknown> {
  const res = await publicApi.post<unknown>(`${registerUri}check_password/`, { password });
  return res.data;
}

import { observer } from 'mobx-react-lite';
import { TextField } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';

const EditPhone = observer((): JSX.Element => {
  const { userStore } = useStore();
  const { t } = useTranslate();

  return (
    <TextField
      id='phone'
      name='phone'
      label={t('phone') as string}
      disabled
      value={userStore.user.mobile_phone}
    />
  );
});

export { EditPhone };

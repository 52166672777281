import { useEffect, useState } from 'react';

export interface ReturnedUseTimer {
  seconds: number;
  restartTimer: () => void;
}

function useTimer(time: number, phone: string): ReturnedUseTimer {
  const [seconds, setSeconds] = useState<number>(time);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();


  // функция для старта/рестарта счетчика СМС под номер телефона
  const initTimer = (restart: boolean = false ):void => {
    const savedCountdown = localStorage.getItem('ditl-lk-code-counter');

    // таймштамп + время которое мы передали
    const newCountdown = new Date().getTime() + time * 1000;

    // если нет счетчика или номера телефона в локал сторейдже или мы его рестартим, то запишем все локал сторейдж и зассетим время
    if (!savedCountdown || !phone.includes(localStorage.getItem('ditl-lk-code-phone') ?? '') || restart) {
      localStorage.setItem('ditl-lk-code-counter', newCountdown?.toString());
      localStorage.setItem('ditl-lk-code-phone', phone);
      setSeconds(time);
    } else {
      // Иначе проверяем не протух ли имеющийся счетчик и начинаем заново или сеттим оставшееся время
      // eslint-disable-next-line
      if (Math.floor((Number(savedCountdown) - new Date().getTime()) / 1000) > 0) {
        setSeconds(Math.floor((Number(savedCountdown) - new Date().getTime()) / 1000));
      } else {
        localStorage.setItem('ditl-lk-code-counter', newCountdown?.toString());
        setSeconds(time);
      }
    }
  }

  useEffect(() => {
    initTimer();
    const id = setInterval(() => {
      setSeconds((prevState) => prevState - 1);
    }, 1000);
    setIntervalId(id);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (seconds === 0 && intervalId) {
      clearInterval(intervalId);
    }
  }, [intervalId, seconds]);

  const restartTimer = (): void => {
    clearInterval(intervalId);
    initTimer(true);

    const id = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    setIntervalId(id);
  };

  return { seconds, restartTimer };
}

export { useTimer };

import { observer } from 'mobx-react-lite';
import { Button } from 'shared/ui';
import { buy } from 'shared/api';
import { appKey } from 'shared/constants';
import { useStore } from 'shared/stores';
import { useTranslate } from 'shared/hooks';
import styles from '../replenishment-balance.module.scss';

interface ReplenishmentSubmitProps {
  setIsPending: (flag: boolean) => void;
}

const ReplenishmentSubmit = observer(({ setIsPending }: ReplenishmentSubmitProps): JSX.Element => {
  const { t } = useTranslate();
  const { replenishmentBalanceStore } = useStore();

  const onSubmit = async (): Promise<void> => {
    setIsPending(true);

    try {
      const res = await buy(Number(replenishmentBalanceStore.amountLotuses));
      localStorage.setItem(`${appKey}_amount_lotuses`, replenishmentBalanceStore.amountLotuses);
      window.location.replace(res.confirmation_url);
    } catch (e) {
      console.error(e);
      setIsPending(false);
    }
  };

  return (
    <Button
      disabled={!replenishmentBalanceStore.isUserAgree || replenishmentBalanceStore.sum === '0'}
      onClick={onSubmit}
      className={styles.replenishment}
      title={t('replenishment')}
    />
  );
});

export { ReplenishmentSubmit };

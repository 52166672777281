import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { UserBalance } from 'entities/user';
import { ErrorMsg, PendingContainer } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';
import { ErrorFormList } from 'shared/constants';
import {
  AboutEWalletPopup,
  ChooseCurrency,
  CurrentRate,
  EnterAmountAndConvertToCurrency,
  EnterNumberWallet,
  WithdrawalSubmit,
  UserAgreements,
} from './components';
import styles from './withdrawal-balance.module.scss';

const WithdrawalBalance = observer((): JSX.Element => {
  const { t } = useTranslate();
  const { errorsStore } = useStore();
  const errorMsg = errorsStore.getError(ErrorFormList.WITHDRAWAL);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    errorsStore.reset();
  }, [errorsStore]);

  const clearErrorMsg = (): void => {
    errorsStore.removeError(ErrorFormList.WITHDRAWAL);
  };

  return (
    <div className={styles.container}>
      <PendingContainer value={isPending}>
        <div className={styles.content}>
          <div className={styles.contentColumns}>
            <h3 className={styles.title}>{t('create_withdrawal_request')}</h3>
            {errorMsg && (
              <ErrorMsg className={styles.errorMsg} error={errorMsg} onClose={clearErrorMsg} />
            )}
            <ChooseCurrency />
            <AboutEWalletPopup />
            <EnterNumberWallet />
            <EnterAmountAndConvertToCurrency />
            <CurrentRate />
            <UserAgreements />
            <WithdrawalSubmit setIsPending={setIsPending} />
          </div>
          <UserBalance className={styles.userBalance} />
        </div>
      </PendingContainer>
    </div>
  );
});

export { WithdrawalBalance };

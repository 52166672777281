import { observer } from 'mobx-react-lite';
import { TextField } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import { useStore } from 'shared/stores';

const EditUsername = observer((): JSX.Element => {
  const { userStore } = useStore();
  const { t } = useTranslate();
  const onChangeFistName = (value: string): void => {
    userStore.updateFirstName(value);
  };

  return (
    <TextField
      id='name'
      name='name'
      label={t('name_surname') as string}
      value={userStore.copyPartUser.first_last_name}
      onChange={onChangeFistName}
    />
  );
});

export { EditUsername };

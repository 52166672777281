import { useState } from 'react';
import { UserBalance } from 'entities/user';
import { useTranslate } from 'shared/hooks';
import { PendingContainer } from 'shared/ui';
import {
  CalculateSumForPay,
  CostInAnotherCurrency,
  LotusCards,
  ReplenishmentSubmit,
  ToggleUserAgreements,
} from './components';
import styles from './replenishment-balance.module.scss';

function ReplenishmentBalance(): JSX.Element {
  const { t } = useTranslate();
  const [isPending, setIsPending] = useState(false);

  return (
    <div className={styles.container}>
      <PendingContainer value={isPending}>
        <div className={styles.content}>
          <div className={styles.contentColumns}>
            <h3 className={styles.title}>{t('replenish_balance')}</h3>
            <LotusCards />
            <CalculateSumForPay />
            <CostInAnotherCurrency />
            <div className={styles.agreements}>
              <ToggleUserAgreements />
              <p>
                {t('by_click_i_accept')}
                <a
                  href='/docs/Terms_of_Use_Ditl.pdf'
                  target='_blank'
                  referrerPolicy='no-referrer'
                  rel='noreferrer'
                >
                  {t('terms')}
                </a>
              </p>
            </div>
            <ReplenishmentSubmit setIsPending={setIsPending} />
          </div>
          <UserBalance className={styles.userBalance} />
        </div>
      </PendingContainer>
    </div>
  );
}

export { ReplenishmentBalance };

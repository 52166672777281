import { ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultAvatar, Photo } from 'shared/ui';
import { useStore } from 'shared/stores';
import styles from './user-avatar.module.scss';

interface UserAvatarProps {
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void | Promise<void>;
}

const UserAvatar = observer(({ disabled = false, onChange }: UserAvatarProps): JSX.Element => {
  const { userStore } = useStore();
  const onChangeFile = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) onChange(e);
  };

  return (
    <div className={styles.container}>
      {userStore.user?.avatar_url ? (
        <img className={styles.image} src={userStore.user?.avatar_url} alt='avatar' />
      ) : (
        <DefaultAvatar />
      )}
      {!disabled && (
        <label className={styles.file} htmlFor='avatar'>
          <Photo />
          <input id='avatar' className={styles.fileInput} type='file' onChange={onChangeFile} />
        </label>
      )}
    </div>
  );
});

export { UserAvatar };

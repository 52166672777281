import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Coin, TextField } from 'shared/ui';
import { Currency } from 'shared/models';
import { convertLotusesToMoney, getCurrency, validateNumber } from 'shared/helpers';
import { useStore } from 'shared/stores';
import { useTranslate } from 'shared/hooks';
import styles from './calculate-sum-for-pay.module.scss';

const CalculateSumForPay = observer((): JSX.Element => {
  const { commonStore, replenishmentBalanceStore } = useStore();
  const rates = commonStore.getRates;
  const { t } = useTranslate();
  const [, setAmount] = useState(replenishmentBalanceStore.amountLotuses);
  const rate = useMemo(() => rates && getCurrency(rates, Currency.RUB), [rates]);

  useEffect(() => {
    if (rates) {
      replenishmentBalanceStore.setSum(
        convertLotusesToMoney(Number(replenishmentBalanceStore.amountLotuses), Currency.RUB, rates),
      );
    }
  }, [rates, replenishmentBalanceStore.amountLotuses]);

  const onChangeAmountLotuses = (value: string): void => {
    if (validateNumber(value)) {
      setAmount(value);
      replenishmentBalanceStore.setAmountLotuses(value);
    } else {
      setAmount('');
      replenishmentBalanceStore.setAmountLotuses('');
    }
  };

  return (
    <>
      <TextField
        id='lotus'
        name='lotus'
        label={t('enter_number_lotuses') as string}
        icon={<Coin className={styles.coinIcon} />}
        onChange={onChangeAmountLotuses}
        value={replenishmentBalanceStore.amountLotuses}
      />
      {rates && (
        <span className={styles.rate}>
          1 {t('lotus')} = {rate} ₽
        </span>
      )}
      {rates && (
        <TextField
          id='sum'
          name='sum'
          label={t('amount_to_pay') as string}
          icon={<i className={styles.ruble}>₽</i>}
          value={replenishmentBalanceStore.sum}
          disabled
        />
      )}
    </>
  );
});

export { CalculateSumForPay };

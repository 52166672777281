import { ReactComponent as GoogleDownloadIcon } from '../../../assets/icons/google-download.svg';
import { ReactComponent as GoogleDownloadEnIcon } from '../../../assets/icons/google-download-en.svg';

interface GoogleDownloadProps {
  className?: string;
  lang?: string;
}

function GoogleDownload({ className = '', lang = 'ru' }: GoogleDownloadProps): JSX.Element {
  const renderIcon = (): JSX.Element => {
    switch (lang) {
      case 'ru':
        return <GoogleDownloadIcon className={className} />;
      case 'en':
        return <GoogleDownloadEnIcon className={className} />;
      default:
        return <GoogleDownloadIcon className={className} />;
    }
  };

  return renderIcon();
}

export { GoogleDownload };

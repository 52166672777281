import { ChangeEvent } from 'react';
import styles from './textarea.module.scss';

interface TextareaProps {
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  textareaClassName?: string;
  label?: string;
  id: string;
  name: string;
  onChange?: (value: string) => void;
  value?: string;
  cols?: number;
  rows?: number;
  error?: string;
  disabled?: boolean;
}

function Textarea({
  id,
  name,
  className = '',
  labelClassName = '',
  label,
  value,
  onChange,
  placeholder,
  cols = 30,
  rows = 10,
  textareaClassName = '',
  error,
  disabled,
}: TextareaProps): JSX.Element {
  const changeHandler = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    if (onChange) onChange(event.target.value);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <label className={`${styles.label} ${labelClassName}`} htmlFor={id}>
        {label}
      </label>
      <textarea
        className={`${styles.textarea} ${textareaClassName} ${error ? styles.errorTextarea : ''}`}
        name={name}
        id={id}
        cols={cols}
        rows={rows}
        placeholder={placeholder}
        onChange={changeHandler}
        disabled={disabled}
        value={value}
      >
        {value}
      </textarea>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
}

export { Textarea };

import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, TextField } from 'shared/ui';
import { isServerError, validatePassword } from 'shared/api';
import { isPasswordValid, validatePasswords } from 'shared/helpers';
import { useStore } from 'shared/stores';
import { ErrorFormList } from 'shared/constants';
import { useTranslate } from 'shared/hooks';
import styles from '../restore-password.module.scss';

interface RestorePasswordProps {
  onSubmit: (password: string) => void | Promise<void>;
  isLoading?: boolean;
}

const EnterNewPassword = observer(({ onSubmit }: RestorePasswordProps): JSX.Element => {
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const { errorsStore } = useStore();
  const [isPending, setIsPending] = useState(false);
  const { t } = useTranslate();

  const onChangePassword = (value: string): void => {
    if (isPasswordValid(value)) setPassword(value);
  };

  const onChangeRepeatedPassword = (value: string): void => {
    setRepeatedPassword(value);
  };

  const submit = async (): Promise<void> => {
    setIsPending(true);

    try {
      await validatePassword(password);
      setIsPending(false);
      errorsStore.removeError(ErrorFormList.PASSWORD);
    } catch (e) {
      if (isServerError(e) && e?.response?.data?.password instanceof Array) {
        errorsStore.setError(ErrorFormList.PASSWORD, e.response.data.password);
      }

      setIsPending(false);
      return;
    }

    if (!validatePasswords(password, repeatedPassword)) {
      errorsStore.setError(ErrorFormList.REPEAT_PASSWORD, t('passwords_not_equals'));
      return;
    }

    setIsPending(false);
    errorsStore.removeError(ErrorFormList.REPEAT_PASSWORD);
    onSubmit(password);
  };

  return (
    <>
      <TextField
        id='password'
        name='password'
        label={t('password-placeholder') as string}
        placeholder={t('password-placeholder') as string}
        type='password'
        className={styles.textFieldCode}
        value={password}
        onChange={onChangePassword}
        error={errorsStore.getError(ErrorFormList.PASSWORD)}
      />
      <TextField
        id='repeat_password'
        name='repeat_password'
        label={t('repeat_password') as string}
        placeholder={t('repeat_password') as string}
        type='password'
        className={styles.textFieldCode}
        value={repeatedPassword}
        onChange={onChangeRepeatedPassword}
        error={errorsStore.getError(ErrorFormList.REPEAT_PASSWORD)}
      />
      <p className={styles.info}>{t('rule_for_password')}</p>
      <Button
        className={`${styles.formSubmit} ${styles.continueBtn}`}
        title={t('continue')}
        variant='default'
        onClick={submit}
        disabled={isPending}
      />
    </>
  );
});

export { EnterNewPassword };

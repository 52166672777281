import { TFunction } from 'i18next';
import { useTranslation, Trans } from 'react-i18next';

interface ReturnedUseTranslate {
  t: TFunction;
  language: string;
  changeLanguage: (lang: string) => Promise<TFunction>;
}

function useTranslate(): ReturnedUseTranslate {
  const { t, i18n,  } = useTranslation();

  return { t, language: i18n.language, changeLanguage: i18n.changeLanguage };
}

export { useTranslate };

function useTrans(): typeof Trans {
  return Trans;
}

export { useTrans };

import { useEffect, useMemo, useState } from 'react';
import { RequestStatus, WithdrawalRequestMobile } from 'entities/withdrawal-request/ui';
import { useTranslate } from 'shared/hooks';
import { FetchWithdrawalList, fetchWithdrawalList } from 'shared/api';
import { formatDate } from 'shared/helpers';
import { PendingContainer } from 'shared/ui';
import styles from './requests-history.module.scss';

function RequestsHistory(): JSX.Element {
  const { t } = useTranslate();
  const [requests, setRequests] = useState<FetchWithdrawalList[]>([]);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    setIsPending(true);

    fetchWithdrawalList()
      .then((res) => {
        setRequests(res);
      })
      .finally(() => {
        setIsPending(false);
      });
  }, []);

  const renderMobileRequests = useMemo(
    () =>
      requests.map((r: FetchWithdrawalList) => (
        <WithdrawalRequestMobile key={r.created_at} request={r} />
      )),
    [requests],
  );

  const renderDesktopRequests = useMemo(
    () =>
      requests.map((r: FetchWithdrawalList) => (
        <div key={r.created_at} className={styles.desktopContentTableRow}>
          <div className={styles.desktopContentTableCell}>{formatDate(r.created_at)}</div>
          <div className={styles.desktopContentTableCell}>{r.amount}</div>
          <div className={styles.desktopContentTableCell}>{r.wallet_address}</div>
          <RequestStatus status={r.status} />
        </div>
      )),
    [requests],
  );

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('requests_history')}</h3>
      <PendingContainer value={isPending}>
        <div className={styles.mobileContent}>{renderMobileRequests}</div>
        <div className={styles.desktopContent}>
          <div className={styles.desktopContentTable}>
            <div className={styles.desktopContentTableHeader}>
              <div>{t('request_date')}</div>
              <div>{t('amount_lotuses')}</div>
              <div>{t('e_wallet_number')}</div>
              <div>{t('request_status')}</div>
            </div>
            <div className={styles.desktopContentTableBody}>{renderDesktopRequests}</div>
          </div>
        </div>
      </PendingContainer>
    </div>
  );
}

export { RequestsHistory };

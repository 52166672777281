import { observer } from 'mobx-react-lite';
import { TextField } from 'shared/ui';
import { useStore } from 'shared/stores';
import { useTranslate } from 'shared/hooks';

const EditNickname = observer((): JSX.Element => {
  const { userStore } = useStore();
  const { t } = useTranslate();

  const onChangeUserName = (value: string): void => {
    userStore.updateUsername(value);
  };

  return (
    <TextField
      value={userStore.copyPartUser.username}
      id='nickname'
      name='nickname'
      label={t('link') as string}
      onChange={onChangeUserName}
    />
  );
});

export { EditNickname };

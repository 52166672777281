// TODO исправить import/no-cycle
// eslint-disable-next-line import/no-cycle
import { publicApi } from './api';
import { authJwtUri } from '../constants';

interface AuthResponse {
  refresh: string;
  access: string;
}

const authUri = authJwtUri;

export async function auth(username: string, password: string): Promise<AuthResponse> {
  const res = await publicApi.post<AuthResponse>(`${authUri}create/`, {
    username,
    password,
  });
  return res.data;
}

type RefreshTokenResponse = Omit<AuthResponse, 'refresh'>;

export async function refreshToken(jwt: string): Promise<RefreshTokenResponse> {
  const res = await publicApi.post<RefreshTokenResponse>(`${authUri}refresh/`, {
    refresh: jwt,
  });
  return res.data;
}

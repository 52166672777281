import { ChangeEvent, useState } from 'react';
import { UserAvatar } from 'entities/user';
import { isServerError, setAvatarUser } from 'shared/api';
import { ErrorFormList } from 'shared/constants';
import { useStore } from 'shared/stores';
import { PendingContainer } from 'shared/ui';
import styles from '../edit-user-profile.module.scss';

function EditUserAvatar(): JSX.Element {
  const { userStore, errorsStore } = useStore();
  const [isPending, setIsPending] = useState(false);

  const onChangeAvatar = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (e?.target?.files?.[0]) {
      setIsPending(true);

      const formData = new FormData();
      formData.append('image', e.target.files[0]);

      try {
        const res = await setAvatarUser(formData);

        if (typeof res?.new_avatar === 'string') {
          userStore.updateAvatar(res.new_avatar);
        }

        errorsStore.removeError(ErrorFormList.AVATAR);
        setIsPending(false);
      } catch (error) {
        if (isServerError(error) && typeof error?.response?.data?.detail === 'string') {
          errorsStore.setError(ErrorFormList.AVATAR, error.response.data.detail);
          setIsPending(false);
          return;
        }

        if (isServerError(error) && error?.response?.data?.image instanceof Array) {
          errorsStore.setError(ErrorFormList.AVATAR, error.response.data.image[0]);
        }

        setIsPending(false);
      }
    }
  };

  return (
    <PendingContainer className={styles.userAvatarSpinner} value={isPending}>
      <UserAvatar onChange={onChangeAvatar} />
    </PendingContainer>
  );
}

export { EditUserAvatar };

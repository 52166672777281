import { ReactComponent as CloseAltIcon } from '../../../assets/icons/close_2.svg';

interface CloseAltProps {
  className?: string;
  onClick?: () => void;
}

function CloseAlt({ className = '', onClick }: CloseAltProps): JSX.Element {
  return <CloseAltIcon className={className} onClick={onClick} />;
}

export { CloseAlt };

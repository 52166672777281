import { ReactComponent as RuDownloadIcon } from '../../../assets/icons/ru-download.svg';

interface RuDownloadProps {
  className?: string;
}

function RuDownload({ className = '' }: RuDownloadProps): JSX.Element {
  return <RuDownloadIcon className={className} />;
}

export { RuDownload };

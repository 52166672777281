export enum ErrorFormList {
  AUTH = 'auth',
  USERNAME = 'username',
  PASSWORD = 'password',
  REPEAT_PASSWORD = 'repeatPassword',
  FIRST_NAME = 'firstLastName',
  ACCOUNT = 'account',
  CODE = '',
  MOBILE_PHONE = 'mobile_phone',
  EMAIL = 'email',
  RESTORE_PASSWORD = 'restore_password',
  REGISTER = 'register',
  AVATAR = 'avatar',
  WITHDRAWAL = 'withdrawal',
  MESSAGE = 'message',
  FEEDBACK = 'feedback',
}

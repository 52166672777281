import { ReactComponent as AcceptIcon } from '../../../assets/icons/accept.svg';

interface AcceptProps {
  className?: string;
}

function Accept({ className = '' }: AcceptProps): JSX.Element {
  return <AcceptIcon className={className} />;
}

export { Accept };
